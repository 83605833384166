//Base Imports
import React, {useState, useEffect} from 'react';
import './BackDrop.css';

//Node Modules Imports

//Files/Components/Containers Imports


let BackDrop = (props) => {

    let [status, setStatus] = useState(null)

    useEffect(() => {
        setStatus(props.status)
    }, [props.status])

    let element=  document.getElementById("backdrop")
    if(element) {
        if(element.classList.contains("show-backdrop") && !status){
            element.classList.remove("show-backdrop")
        }
        if(!element.classList.contains("show-backdrop") && status){
            element.classList.add("show-backdrop")
        }
    }

    return (
        <div
            id={"backdrop"}
            className={"backdrop"}
            onClick={() => props.backDropClick()}/>
    )
}

export default BackDrop