//Base Import
import React from "react"
import "./PwForget.css"

//Node Modules Imports

//Files/Component/Container Imports

let PwForget = (props) => {
    return (
        <div className={"pw-forget-container"}>

        </div>
    )
}

export default PwForget