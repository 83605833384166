//Base Import
import React, {useState, useEffect} from "react"
import "./Dashboard.css"

//Node Modules Imports
import {connect}             from "react-redux";
import UserDashboard         from "../UserDashboard/UserDashboard";
import AdminDashboard        from "../AdminDashboard/AdminDashboard";
import {useFirestoreConnect} from "react-redux-firebase";
import {Redirect}            from "react-router";
import * as ROUTES           from "../../../constants/routes/routes";

//Files/Component/Container Imports

//Dashboard Component: Redirects to the User to the Appropriate Dashboard
let Dashboard = (props) => {
    let [isSignedIn, setIsSignedIn] = useState(true)
    let [isAdmin, setIsAdmin]       = useState(false)
    let [isLoading, setIsLoading]   = useState(true)
    let [userPath, setUserPath]     = useState([{collection : 'accounts', doc : 'usersList'}])
    let [adminPath, setAdminPath]   = useState([{collection : 'accounts', doc : 'adminsList'}])

    useEffect(() => {

        //If Signed-In
        if(props.data && props.passwordSignIn && props.passwordSignIn.signedIn && props.phoneSignIn && props.phoneSignIn.signedIn){

            //If Admin
            if(props.data['accounts'] && props.data['accounts']['adminsList'] && props.data['accounts']['adminsList'].list && props.data['accounts']['adminsList'].list.includes(props.auth.currentUser.uid)){
                setIsAdmin(true)
                setIsLoading(false)
            }
            else {
                //If not Admin
                setIsAdmin(false)
                setIsLoading(false)
            }
        }
        else {
            //If Not Signed-In
            setIsSignedIn(false)
        }

    }, [props.phoneSignIn, props.passwordSignIn, props.data, props.data.accounts])

    //Get Data from Firestore,
    // by passing the Firestore Collection Query to Firestore Connect.
    //Data once received fires up the useEffect above to update state of this component.
    useFirestoreConnect(() => adminPath)
    useFirestoreConnect(() => userPath)

    let data = null

    //Page is Loading
    if(isLoading){
        //Show "Loading" on Page
        data = (
            <div className={"data-cards-loading"}>Loading...</div>
        )
    }

    //Page has Loaded
    if(!isLoading){
        if(isAdmin){
            //Redirect to AdminDashboard
            data = <AdminDashboard isAdmin={isAdmin}/>
        }
        else {
            //Redirect to UserDashboard
            data = <UserDashboard isAdmin={isAdmin} />
        }
    }

    //If not LoggedIn, Redirect to SignIn-Page
    if(!isSignedIn){
        data =  <Redirect to={ROUTES.SIGN_IN}/>
    }

    return (
        <div className={"dashboard-container"}>
            {data}
        </div>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state) => {
    //console.log("DASHBOARD - ", state)
    return {
        auth           : state.auth,
        passwordSignIn : state.auth.passwordSignIn,
        phoneSignIn    : state.auth.phoneSignIn,
        data           : state.firestore.data
    }
}

//Connect to the Redux Store State and Export the Component
export default connect(mapStateToProps)(Dashboard)
