//Custom Reducers
import authReducer from "./authReducer"
import projectReducer from "./projectReducer";
import dashBoardReducer from "./dashBoardReducer";

import {combineReducers} from "redux";
import {firebaseReducer} from "react-redux-firebase";
import {firestoreReducer} from "redux-firestore";

const rootReducer = combineReducers({
    auth     : authReducer,
    project  : projectReducer,
    data     : dashBoardReducer,
    firebase : firebaseReducer,
    firestore: firestoreReducer
})

export default rootReducer