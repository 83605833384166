//Base Import
import React, {useEffect, useState} from "react"
import "./DataInfo.css"

//Node Modules Imports

//Files/Component/Container Imports

//DataInfo Component: Shows Vehicle Data Info Card containing Info at a particular instant
let DataInfo = (props) => {

    let [locationInfo, setLocationInfo] = useState({
        Altitude       : null,
        Depth          : null,
        Longitude      : null,
        Latitude       : null,
        Heading        : null,
        SurgeVel       : null,
        day            : null,
        trackerStatus  : null,
        status         : null,
        time           : null,
        timeDataSend   : null,
        locationsArray : null,
        predictionArray : null

    })

    //Set Location Info and Call Map Data Handler
    useEffect(() => {

        console.log("DATA INFO TRACKER STATUS - ", props.trackerStatus)


        //If Data(days data instant) is loaded
        if(props.data) {
            let newInfo = {
                Altitude         : props.data.Altitude,
                Depth            : props.data.Depth,
                Longitude        : props.data.Longitude,
                Latitude         : props.data.Latitude,
                Heading          : props.data.Heading,
                SurgeVel         : props.data.SurgeVel,
                day              : props.day,
                trackerStatus    : props.trackerStatus,
                status           : props.status,
                data_status      : props.data_status,
                time             : props.time,
                timeDataSend     : props.data.timeDataSend,
                missionLocations : props.missionLocations,
                locationsArray   : props.locations,
                predictionArray  : props.predictionArray,
                timeEstimateArray: props.timeEstimateArray
            }

            //Set Location Info.
            setLocationInfo(newInfo)

            //If Status is Defined and Data instant is the Last Data of the Day
            if((props.status === "previous-status" || props.status === "current-status") && props.trackerStatus !== "Predicted" && props.lastDayData){
                console.log("MISSION ARRAY - data received at DATA INFO CURRENT", newInfo.predictionArray, newInfo.missionLocations, props.timeEstimateArray)
                props.mapDataHandler(newInfo)
            }

            if(props.status === "current-status" && props.trackerStatus === "Predicted" && props.lastDayData){
                console.log("MISSION ARRAY - data received at DATA INFO PREDICTED", newInfo.predictionArray, newInfo.missionLocations, props.timeEstimateArray)

                props.mapDataHandler(newInfo)
            }
        }
    }, [props.data, props.trackerStatus, props.predictionArray])

    return (
        <div className={"data-info-container"} onClick={() =>  {props.mapDataHandler(locationInfo)}}>
            <div className={"time-data-send"}>
                <h5>
                    {(props.data_status === "Predicted") ? `Time Estimate :` : `Time Data Received :`}
                    <div>
                        {(props.data_status === "Predicted") ?
                            `${props.data.Time_estimate}` :
                            `${props.data.timeDataSend.HH}:${props.data.timeDataSend.MN}:${props.data.timeDataSend.SS} PST`}
                        {/*{props.data.timeDataSend.HH}:{props.data.timeDataSend.MN}:{props.data.timeDataSend.SS} PST*/}
                    </div>
                </h5>
            </div>
            <div className={"location"}>
                <i className="fas fa-map-marker-alt"></i>
                <div className={"latitude"}>
                    {/*{(props.data_status === "Predicted") ? parseFloat(props.predicted_data.Latitude).toFixed(2) : parseFloat(props.data.Latitude).toFixed(2)}*/}
                    {parseFloat(props.data.Latitude).toFixed(2)}
                </div>
                <div className={"comma"}>
                    ,
                </div>
                <div className={"longitude"}>
                    {/*{(props.data_status === "Predicted") ? parseFloat(props.predicted_data.Longitude).toFixed(2) : parseFloat(props.data.Longitude).toFixed(2)}*/}
                    {parseFloat(props.data.Longitude).toFixed(2)}
                </div>
            </div>
            <div className={"radius_of_acceptance"}>
                {(props.data_status === "Predicted" && props.data.Radius_of_acceptance) ? `Radius of Acceptance: ${Math.ceil(parseFloat(props.data.Radius_of_acceptance))}m` : null}
            </div>
            <div className={"heading"}>
                Heading: {Math.ceil(parseFloat(props.data.Heading))}
                {/*{(props.data_status === "Predicted") ? Math.ceil(parseFloat(props.predicted_data.Heading)) : Math.ceil(parseFloat(props.data.Heading))}*/}
                <span className={"unit"}>&#176;</span>
            </div>
            <div className={"speed"}>
                Speed: {parseFloat(props.data.SurgeVel).toFixed(2)}
                {/*{(props.data_status === "Predicted") ? parseFloat(props.predicted_data.SurgeVel).toFixed(2) : parseFloat(props.data.SurgeVel).toFixed(2)}*/}
                <span className={"unit"}>m/s</span>
            </div>
            <div className={"altitude"}>
                Altitude: {parseFloat(props.data.Altitude).toFixed(0)}
                {/*{(props.data_status === "Predicted") ? parseFloat(props.predicted_data.Altitude).toFixed(0) : parseFloat(props.data.Altitude).toFixed(0)}*/}
                <span className={"unit"}>m</span>
            </div>
            <div className={"depth"}>
                Depth: {parseFloat(props.data.Depth).toFixed(1)}
                {/*{(props.data_status === "Predicted") ? parseFloat(props.predicted_data.Depth).toFixed(1) : parseFloat(props.data.Depth).toFixed(1)}*/}
                <span className={"unit"}>m</span>
            </div>

        </div>
    )
}

//Export Component
export default DataInfo