//Base Import
import React, {useState} from "react"
import "./AddNewAccountModal.css"

//Node Modules Imports
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

//Files/Component/Container Imports

let AddNewAccountModal = (props) => {
    let [newName, setNewName]             = useState("")
    let [newEmail, setNewEmail]           = useState("")
    let [newPhone, setNewPhone]           = useState("")
    let [cellula_staff, setCellula_staff] = useState("No")

    let infoChangeHandler = (e) => {
        if(e.currentTarget.name === "newName"){
            setNewName(e.currentTarget.value)
        }
        if(e.currentTarget.name === "newPhone"){
            setNewPhone(e.currentTarget.value)
        }
        if(e.currentTarget.name === "newEmail"){
            setNewEmail(e.currentTarget.value)
        }
        if(e.currentTarget.name === "cellula_staff"){
            setCellula_staff(e.currentTarget.value)
        }
    }

    return (
        <div className={"addNewAccountModal-container"}>
            <Modal className={"newAccount-modal"} show={props.show} onHide={props.handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New {props.type}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter Email"
                                name={"newEmail"}
                                value={newEmail}
                                onChange={infoChangeHandler}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="name"
                                placeholder="Enter Name"
                                name={"newName"}
                                value={newName}
                                onChange={infoChangeHandler}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="phone"
                                placeholder="Enter Phone e.g. +17787173333"
                                name={"newPhone"}
                                value={newPhone}
                                onChange={infoChangeHandler}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Cellula Staff <sup>(1)</sup> </Form.Label>
                            <Form.Control
                                as="select"
                                name={"cellula_staff"}
                                value={cellula_staff}
                                onChange={infoChangeHandler}>
                                <option>Yes</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Label className={"cellula_staff_reference"}>(1) Cellula Staff is not required to complete phone verification</Form.Label>
                        </Form.Group>

                        <Form.Group className={"account-info-msg"}>
                            <Form.Label>{props.accountInfoMsg}</Form.Label>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={"add-btn"} variant="primary" onClick={() => props.submit(props.type, newEmail, newName, newPhone, cellula_staff)}>
                        Add {props.type}
                    </Button>
                    <Button className={"close-btn"} variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddNewAccountModal