//Base Import
import React from "react"
import "./AdminDashboard.css"

//Node Modules Imports
import {connect} from "react-redux"

//Files/Component/Container Imports
import UserDashboard from "../UserDashboard/UserDashboard";

//AdminDashboard Component: Simply Wraps the UserDashboard Component if the user was an Admin
let AdminDashboard = (props) => {

    return (
        <div className={"adminDashboard-container"}>
            <UserDashboard isAdmin={props.isAdmin}/>
        </div>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state) => {
    return {
        auth : state.auth,
        data : state.firestore.data
    }
}

//Connect to the Redux Store State and Export the Component
export default connect(mapStateToProps)(AdminDashboard)
