//Base Import
import React, {useState, useEffect} from "react"
import "./DataCard.css"

//Node Modules Imports

//Files/Component/Container Imports
import DataInfo from "../DataInfo/DataInfo";
import * as DATE from "../../../../constants/date/date";

//DataCard Component: Shows Vehicle Data Card containing Data Info
let DataCard = (props) => {
    let [dataArray, setDataArray]             = useState([])
    let [dataInfo, setDataInfo]               = useState([])
    let [predictionInfo, setPredictionInfo]   = useState([])
    let [lastComm, setLastComm]               = useState('-')
    let [locationsArray, setLocationsArray]   = useState([])
    let [predictionArray, setPredictionArray] = useState([])
    let [npp, setNpp]                         = useState(null)
    let [timeEstimateArr, setTimeEstimateArr] = useState([])
    let [mapPredictionData, setMapPredictionData] = useState({
        prediction_index    : 0,
        prediction_point    : null,
        prediction_point_distance : 0
    })

    //Set Data(Days Data) and Locations(Days Data Locations) Array
    useEffect(() => {

        console.log("=================== DATA CARDS TRACKER STATUS - ", props.trackerStatus)

        //Set Days(selected day or most recent day) Array
        setDataArray(props.data);

        //Set Locations Array
        setLocationsArray(

            //Iterate through the days data
            props.data
                .map((data, index) => {

                    //Get Location(latitude, longitude), Heading anD Time(Hour, Min) from each data
                    let lat         = parseFloat(data.entry.data.Latitude)
                    let lng         = parseFloat(data.entry.data.Longitude)
                    let depth       = parseFloat(data.entry.data.Depth)
                    let altitude    = parseFloat(data.entry.data.Altitude)
                    let heading     = parseFloat(data.entry.data.Heading)
                    let speed       = parseFloat(data.entry.data.SurgeVel)
                    let hour        = parseFloat(data.entry.data.timeDataSend.HH)
                    let min         = parseFloat(data.entry.data.timeDataSend.MN)
                    let sec         = parseFloat(data.entry.data.timeDataSend.SS)
                    let date        = parseFloat(data.entry.sec)
                    let data_status = "Current"

                    //To avoid on start data, which is lat and long zero i.e. equator
                    //If goes to equator(solus-lr) this can create a problem
                    //Not an issue to deal with right now
                    if(lat === 0 || lng === 0) {
                        return null
                    }

                    //Return Location Data
                    return {
                        lat: lat,
                        lng: lng,
                        depth : depth,
                        altitude : altitude,
                        heading : heading,
                        speed : speed,
                        time : {HH : hour, MN : min, SS : sec},
                        sec : date,
                        data_status : data_status
                    }
                })
                .filter((location) => {
                    //Filter to Remove any null values(i.e. lat === 0 && lng === 0)
                    return location != null
                })
        )
    },[props.data, props.status, props.trackerStatus, props.selectedTime])

    useEffect(() => {
        if(locationsArray && locationsArray.length !== 0 && props.trackerStatus && (props.trackerStatus === "Current" || props.trackerStatus === "Predicted") && props.showPrediction && props.missionLocationArray){

            //console.log("MISSION ARRAY - data received at DATA CARDS", props.missionLocationArray)

            if(props.showPrediction && props.missionLocationArray && props.missionLocationArray.length !== 0){

                //Current Location marker will the most recently received data
                let currentPosition = locationsArray[0]

                //Get the Nearest Prediction Position i.e. modify the MissionLocation based on currentLocation
                let object_npp = get_npp(props.trackerStatus, currentPosition, props.missionLocationArray)

                let prediction_point_alt = 0

                console.log("MISSION ARRAY POINTS ===================== ", props.trackerStatus)
                console.log("MISSION ARRAY NPP RESULTS - ", object_npp)

                let first_pos_arrived = null

                if(props.trackerStatus === "Predicted"){

                    //Assign Defaults
                    // setPredictionArray([])
                    // setMapPredictionData({
                    //     prediction_index    : 0,
                    //     prediction_point    : null,
                    //     prediction_point_distance : 0
                    // })

                    //Current Time Info
                    let date = new Date()
                    let current_hour    = date.getHours()
                    let current_minutes = date.getMinutes()
                    let current_seconds = date.getSeconds()

                    let current_total_seconds = (current_hour * 3600) + (current_minutes * 60) + (current_seconds)
                    let current_pos_seconds   = (currentPosition.time.HH * 3600) + (currentPosition.time.MN * 60) + (currentPosition.time.SS)

                    let time_elapsed = Math.abs(current_total_seconds - current_pos_seconds)//remove abs

                    //console.log("MISSION ARRAY - TIME ELAPSED      - ", time_elapsed, "First Position Arrived - ", first_pos_arrived)

                    let time_prediction_array = get_prediction_time_array(currentPosition, props.missionLocationArray, object_npp.minimum_point_index)

                    console.log("MISSION ARRAY POINTS - npp results", object_npp.minimum_point_index, "time elapsed - ", time_elapsed)
                    console.log("MISSION ARRAY POINTS - time prediction array ", time_prediction_array)

                    console.log(time_prediction_array)

                    for (let index = 0; index < time_prediction_array.length; index++) {

                        console.log("MISSION ARRAY POINTS index", index, time_prediction_array[index], time_elapsed)

                        if (time_prediction_array[index] >= time_elapsed) {

                            let prev_index = index-1 //(index !== 0) ? index-- : 0

                            if(prev_index === -1){
                                console.log("MISSION ARRAY POINTS - TIME PREDICTION BREAKING LOOP")
                                first_pos_arrived = false
                                break
                            }

                            // if(prev_index + object_npp.minimum_point_index < 0){
                            //     console.log("MISSION ARRAY POINTS - TIME PREDICTION BREAKING LOOP")
                            //     first_pos_arrived = false
                            //     break
                            // }

                            first_pos_arrived = true

                            //Break the loop
                            index = props.missionLocationArray.length - 1

                            setMapPredictionData({
                                prediction_index: prev_index + object_npp.minimum_point_index,
                                prediction_point: props.missionLocationArray[prev_index + object_npp.minimum_point_index],
                                prediction_point_distance: get_distance_between_points(props.missionLocationArray[prev_index + object_npp.minimum_point_index], currentPosition)
                            })
                            prediction_point_alt = prev_index + object_npp.minimum_point_index

                            console.log("MISSION ARRAY POINTS - HAS ARRIVED AT POINT - ", mapPredictionData.prediction_index, prediction_point_alt)

                            //End the loop
                            //index = time_prediction_array.length - 1
                            break
                        }
                        else {

                            console.log("MISSION ARRAY POINTS - IS GOING TO NEXT POINT - ",  mapPredictionData.prediction_index, index + object_npp.minimum_point_index)

                            if (index === time_prediction_array.length - 1) {
                                console.log("MISSION ARRAY POINTS - ALL POINTS HAVE BEEN VISITED", index)

                                first_pos_arrived = true

                                setMapPredictionData({
                                    prediction_index: index + object_npp.minimum_point_index,
                                    prediction_point: props.missionLocationArray[index + object_npp.minimum_point_index],
                                    prediction_point_distance: get_distance_between_points(props.missionLocationArray[index + object_npp.minimum_point_index], currentPosition)
                                })
                                prediction_point_alt = index + object_npp.minimum_point_index
                            }
                        }
                    }
                }

                console.log("MISSION ARRAY POINTS - setting prediction array ", mapPredictionData.prediction_index, "TEST POINT - ", prediction_point_alt)

                let time_estimate_array = []

                console.log("SET PREDICTION ARRAY NPP RESULTS - ", object_npp, props.trackerStatus)

                setPredictionArray(
                    props.missionLocationArray
                        .map((data, index) => {

                            if((props.trackerStatus === "Current" && index >= object_npp.minimum_point_index) || (props.trackerStatus === "Predicted" && index >= object_npp.minimum_point_index && index <= prediction_point_alt && first_pos_arrived)){//&& index <= prediction_point_alt //usually "index <= prediction_point_alt" is "index <= mapPredictionData.prediction_index" but since we an error setting(REACT CURRENT VERSION PROBLEM) it above we use the alternative.

                                console.log("SET PREDICTION ARRAY - ", index)

                                //Get Location(latitude, longitude), Heading anD Time(Hour, Min) from each data
                                let lat                  = parseFloat(data.lat)
                                let lng                  = parseFloat(data.lng)
                                let depth                = parseFloat(data.depth)
                                let heading              = parseFloat(data.heading)
                                let speed                = parseFloat(data.speed)
                                let radius_of_acceptance = parseFloat(data.radius_of_acceptance)
                                let time_from_prev       = data.time_from_prev
                                let data_status          = "Predicted"

                                let time_prediction_array = get_prediction_time_array(currentPosition, props.missionLocationArray, object_npp.minimum_point_index)
                                let time_estimate  = currentPosition.time.MN + (time_prediction_array[index - object_npp.minimum_point_index])/60

                                console.log("SET PREDICTION ARRAY - time prediction array", time_prediction_array)
                                console.log("SET PREDICTION ARRAY - time estimate1 - index", time_estimate, index)
                                if(time_estimate >= 60){

                                    let hour_progress = Math.floor(time_estimate/60)
                                    let min_progress  = Math.ceil(((time_estimate/60) - hour_progress) * 60)
                                    let sec_progress = 0

                                    time_estimate = `${currentPosition.time.HH + hour_progress}:${min_progress}:${sec_progress} PST`
                                }
                                else {
                                    time_estimate = `${currentPosition.time.HH}:${Math.ceil(time_estimate)}:00 PST`
                                }

                                console.log("SET PREDICTION ARRAY - time estimate2", time_estimate)

                                time_estimate_array.push({
                                    lat : lat,
                                    lng : lng,
                                    time_estimate : {
                                        HH : time_estimate.split(':')[0],
                                        MN : time_estimate.split(':')[1],
                                        SS : time_estimate.split(':')[2]
                                    }
                                })

                                //To avoid on start data, which is lat and long zero i.e. equator
                                //If goes to equator(solus-lr) this can create a problem
                                //Not an issue to deal with right now
                                if(lat === 0 || lng === 0) {
                                    return null
                                }

                                //Return Location Data
                                return {
                                    lat: lat,
                                    lng: lng,
                                    depth : depth,
                                    heading : heading,
                                    speed : speed,
                                    radius_of_acceptance : radius_of_acceptance,
                                    time_from_prev : time_from_prev,
                                    time_estimate : time_estimate,
                                    time : {HH : time_estimate.split(':')[0], MN : time_estimate.split(':')[1], SS : time_estimate.split(':')[2]},
                                    data_status : data_status
                                }
                            }
                            else {
                                 return null
                            }

                        })
                        .filter((location) => {
                            return location != null
                        })
                )

                //console.log("MISSION ARRAY - time estimate array - ", time_estimate_array)
                //Set Time Estimate Array
                setTimeEstimateArr(
                    props.missionLocationArray
                        .map((data, index) => {

                            if(index >= object_npp.minimum_point_index){
                                //Get Location(latitude, longitude), Heading anD Time(Hour, Min) from each data
                                let lat                  = parseFloat(data.lat)
                                let lng                  = parseFloat(data.lng)

                                let time_prediction_array = get_prediction_time_array(currentPosition, props.missionLocationArray, object_npp.minimum_point_index)
                                let time_estimate  = currentPosition.time.MN + (time_prediction_array[index - object_npp.minimum_point_index])/60

                                console.log("SET PREDICTION ARRAY - time estimate1", time_estimate)
                                if(time_estimate >= 60){

                                    let hour_progress = Math.floor(time_estimate/60)
                                    let min_progress  = Math.ceil(((time_estimate/60) - hour_progress) * 60)
                                    let sec_progress = 0

                                    time_estimate = `${currentPosition.time.HH + hour_progress}:${min_progress}:${sec_progress} PST`
                                }
                                else {
                                    time_estimate = `${currentPosition.time.HH}:${Math.ceil(time_estimate)}:00 PST`
                                }

                                console.log("SET PREDICTION ARRAY - time estimate2", time_estimate)

                                return {
                                    lat : lat,
                                    lng : lng,
                                    time_estimate : {
                                        HH : time_estimate.split(':')[0],
                                        MN : time_estimate.split(':')[1],
                                        SS : time_estimate.split(':')[2]
                                    }
                                }
                            }
                        })
                        .filter((data) => {
                            return (data !== null && data !== undefined)
                        })
                )
            }
        }
    }, [locationsArray, props.trackerStatus, props.showPrediction, props.missionLocationArray])

    //Set Last Communication and Data Info Cards
    useEffect(() => {

        //If Data(Days Data) Array and Locations(Days Data Locations) Array is defined
        if(dataArray.length !== 0 && locationsArray.length !== 0){

            //Set Data Info Cards
            setDataInfo(
                getDataInfoArray()
            )

            let index = 0
            //Props.day already shows the day, so it is obvious last recorded info/status is of that day
            //therefore we show that info instead of showing 'no data recorded for today'

            //Get Last Communicated Time(Hour, Min)
            let hour = dataArray[index].entry.time.split(':')[0]
            let min  = dataArray[index].entry.time.split(':')[1]

            //Set Last Communication
            setLastComm (
                `${dataArray[index].entry.day} at ${hour}:${min} PST`
            )
        }
    }, [dataArray, locationsArray])

    useEffect(() => {

        if(predictionArray && predictionArray.length !== 0){

            console.log("MISSION ARRAY - ", predictionArray, "Tracker Status - ", props.trackerStatus)

            if(props.trackerStatus === "Predicted"){

                console.log("Prediction Array with Predicted - ", predictionArray, props.trackerStatus)
                setPredictionInfo(
                    getPredictionInfoArray().reverse()
                )
            }
            else {

                console.log("Prediction Array with Current - ", predictionArray, props.trackerStatus)
                setPredictionInfo([])
                setDataInfo(getDataInfoArray())
            }

        }
    }, [predictionArray, timeEstimateArr])

    //Get distance between two points
    let get_distance_between_points = (pt1, pt2) => {

        //Haversine formula
        let R = 6371; // Radius of the earth in km
        let dLat = deg2rad(pt2.lat-pt1.lat);  // deg2rad below
        let dLon = deg2rad(pt2.lng-pt1.lng);
        let a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(deg2rad(pt1.lat)) * Math.cos(deg2rad(pt2.lat)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        let distance = R * c; // Distance in km
        distance = (distance * 1000).toFixed(0);  //Distance in meters

        //let time = (distance/speed).toFixed(2)

        return parseFloat(distance)
    }

    //Convert degree to radians
    let deg2rad = (deg) => {
        return deg * (Math.PI/180)
    }

    //Get next/nearest prediction position
    let get_npp = (tracker_status, currentPos, missionArray) => {

        console.log("NPP Calc - ", currentPos, missionArray)

        //Next min. point to the current position
        let minimum_point = null

        //Next min. point index
        let minimum_point_index = null

        //Next min. distance from current position
        let minimum_distance = Number.MAX_SAFE_INTEGER

        for(let index = 0; index < missionArray.length; index++){

            let distance_between = get_distance_between_points(missionArray[index], currentPos)

            //Getting the min. distance
            if(distance_between <= minimum_distance){

                minimum_distance = distance_between
                minimum_point = missionArray[index]
                minimum_point_index = index
            }

        }

        //If the minimum point is inside the radius of acceptance
        // if(minimum_point && (minimum_point.radius_of_acceptance > minimum_distance) && (minimum_point_index + 1 <= missionArray.length - 1)){
        //
        //     //switch to next point
        //     minimum_point = missionArray[minimum_point_index + 1]
        //     minimum_distance = get_distance_between_points(missionArray[minimum_point_index + 1], currentPos)
        //     minimum_point_index = minimum_point_index + 1
        // }

        // if(minimum_point_index === missionArray.length - 1 && tracker_status === "Current"){
        //     //all completed
        // }

        // setMapPredictionData({
        //     prediction_index : minimum_point_index,
        //     prediction_point : minimum_point,
        //     prediction_point_distance : minimum_distance
        // })

        // setNpp(minimum_point_index)

        console.log("NPP DONE - ", minimum_distance)

        return {
            "minimum_distance"    : minimum_distance,
            "minimum_point"       : minimum_point,
            "minimum_point_index" : minimum_point_index
        }
    }

    let get_prediction_time_array = (currentPos, missionArray, min_point_index) => {

        console.log("SET PREDICTION ARRAY TIME - get time prediction array - ", missionArray[min_point_index], min_point_index, currentPos)

        let array = new Array(missionArray.length - min_point_index)

        let distance = get_distance_between_points(currentPos, missionArray[min_point_index])
        let time0 = distance/currentPos.speed

        let temp_time = time0

        for(let index = 0; index < array.length; index++){

            console.log("SET PREDICTION ARRAY - index", index, "time", missionArray[min_point_index + index].time_from_prev)

            if(index === 0){

                console.log("SET PREDICTION ARRAY - index", index, "time", time0)
                array[index] = time0
            }

            // if(min_point_index === array.length - 1){
            //     temp_time = temp_time + missionArray[min_point_index].time_from_prev.time
            //     array[index] = temp_time
            // }
            else {
                temp_time = temp_time + missionArray[min_point_index + index].time_from_prev.time
                array[index] = temp_time
            }
        }

        return array
    }

    //Get Data Info Cards Array
    let getDataInfoArray = () => {

        console.log("GET DATA INFO ARRAY - ", predictionArray)

        return (
            //Iterate through Days data
            dataArray.map((data, index) => {

                //Return DataInfo Card with Data of Days(selected or most recent day) Data
                return (
                    <DataInfo
                        lastDayData      = {(0 === index)}   //Returns true if index = 0, to signify most recent info/data
                        data             = {data.entry.data} //Days particular instant Data
                        date             = {data.entry.sec}
                        mapDataHandler   = {props.mapDataHandler}
                        missionLocations = {props.missionLocationArray}
                        predictionArray  = {predictionArray}
                        locations        = {locationsArray}
                        timeEstimateArray= {timeEstimateArr}
                        data_status      = "Current"
                        status           = {props.status} //Current or Previous Status
                        day              = {props.day} //Selected or Most Recent Day
                        trackerStatus    = {props.trackerStatus}
                    />)
            })
        )
    }

    let getPredictionInfoArray = () => {
        return (

            predictionArray
                .map((data, index) => {

                    let info = {
                        Latitude : (data.lat)      ? data.lat      : "Not Available",
                        Longitude: (data.lng)      ? data.lng      : "Not Available",
                        SurgeVel : (data.speed)    ? data.speed    : "Not Available",
                        Depth    : (data.depth)    ? data.depth    : "Not Available",
                        Altitude : (data.altitude) ? data.altitude : "Not Available",
                        Heading  : (data.heading)  ? data.heading  : "Not Available",
                        Time_estimate : (data.time_estimate) ? data.time_estimate : "Not Available",
                        Radius_of_acceptance : (data.radius_of_acceptance) ? data.radius_of_acceptance : "Not Available"
                    }

                    return (
                        <DataInfo
                            lastDayData      = {predictionArray.length - 1 === index}   //Returns true if index = last of array, to signify most recent info/data
                            data             = {info}
                            predicted_data   = {info} //Days particular instant Data
                            date             = {null}
                            mapDataHandler   = {props.mapDataHandler}
                            missionLocations = {props.missionLocationArray}
                            predictionArray  = {predictionArray}
                            locations        = {locationsArray}
                            timeEstimateArray= {timeEstimateArr}
                            data_status      = "Predicted"
                            status           = {props.status} //Current or Previous Status
                            day              = {props.day} //Selected or Most Recent Day
                            trackerStatus    = {props.trackerStatus}
                        />)
                })
        )
    }

    //Get Current Time
    let getCurrentTime = (type) => {
        let currentTime = new Date()

        if(type === "string"){
            let hour = (currentTime.getHours().toString().trim())
            let min  = currentTime.getMinutes().toString().trim()

            //Return Formatted Time
            return (
                ((hour<10) ? `0${hour}` : hour) + ":" +
                ((min<10)  ? `0${min}`  : min)  + " PST"
            )
        }
        return null
    }

    let currentDate = new Date()
    let currentDay  = `${DATE.WEEK_DAYS[currentDate.getDay()-1]}, ${currentDate.getDate()} ${DATE.YEAR_MONTHS[currentDate.getMonth()]} ${currentDate.getFullYear()}`
    return (
        <div className={"data-card-container"}>
            <div className={"main-info"}>
                <div className={"current-mission-day"}>
                    <h3>
                        {(props.status === "current-status") ? currentDay : props.day}
                    </h3>
                </div>
                <div className={"current-mission-name"}>
                    <h6>
                        Current Mission : {props.currentMission}
                    </h6>
                </div>
                <div className={"current-time"}>
                    <h6>
                        Current Time: {getCurrentTime("string")}
                    </h6>
                </div>
                <div className={"tracker-status"}>
                    <h6>
                        Tracker Status : {props.trackerStatus}
                    </h6>
                </div>
                <div className={"last-communication"}>
                    <h6>
                        Last Communication at : {lastComm}
                    </h6>
                </div>
            </div>

            <div className={"vertical-line"}></div>

            <div className={"data-info"}>
                <div className={"current-status-message"}>
                    {(currentDay !== props.day && props.status === "current-status") ? `Displaying Data For ${props.day}` : `N/A`}
                </div>
                {predictionInfo}
                {dataInfo}
            </div>
        </div>
    )
}

//Export Component
export default DataCard