//Base Import
import React, {useState, useEffect} from "react"
import "./AccountInfo.css"

//Node Modules Imports
import {connect}             from "react-redux";
import {useFirestoreConnect} from "react-redux-firebase";

//Files/Component/Container Imports
import Accounts    from "./Accounts/Accounts";
import {Redirect}  from "react-router";
import * as ROUTES from "../../../constants/routes/routes";
import UserAccount from "./UserAccount/UserAccount";
import AdminAccount from "./AdminAccount/AdminAccount";

let AccountInfo = (props) => {

    let [allUsersList, setAllUsersList]   = useState([])
    let [allAdminsList, setAllAdminsList] = useState([])
    let [status, setStatus]               = useState(null)
    let [isAdmin, setIsAdmin]             = useState(false)
    let [adminPath, setAdminPath]         = useState([{collection : 'accounts', doc : 'adminsList'}])
    let [userPath, setUserPath]           = useState([{collection : 'accounts', doc : 'usersList'}])


    useEffect(() => {
        if(props.data && props.data.accounts && props.data.accounts.usersList && props.data.accounts.usersList.list) {
            setAllUsersList(
                props.data.accounts.usersList.list
            )
        }
        if(props.data && props.data.accounts && props.data.accounts.adminsList && props.data.accounts.adminsList.list){
            setAllAdminsList(
                props.data.accounts.adminsList.list
            )

            if(props.auth && props.data.accounts.adminsList.list.includes(props.auth.currentUser.uid)){
                setIsAdmin(true)
            }
        }

        if(props.auth && props.auth.currentUser && props.data.accounts){
            //To re-run useFirestoreConnect and get the new list of users/admins
            setAdminPath([{collection : 'accounts', doc : 'adminsList'}])
            setUserPath([{collection : 'accounts', doc : 'usersList'}])
        }
    }, [props.data.accounts])

    useFirestoreConnect(() => adminPath)
    useFirestoreConnect(() => userPath)

    let getCollectionQuery = (type, list) => {
        return list.map(id => {
            return {
                collection: 'accounts',
                doc: type,
                subcollections: [
                    {
                        collection: id
                    }
                ]
            }
        })
    }

    let userDataCollections = []
    let adminDataCollections = []
    if(allUsersList.length !== 0){
        userDataCollections = getCollectionQuery('users', allUsersList)
    }
    if(allAdminsList.length !== 0){
        adminDataCollections = getCollectionQuery('admins', allAdminsList)
    }
    useFirestoreConnect(() => userDataCollections)
    useFirestoreConnect(() => adminDataCollections)

    let adminControlStatusHandler = (type) => {
        let listType = `all${type[0].toUpperCase() + type.substring(1)}List`
        setStatus(
            <Accounts type={type} accountList={listType} isAdmin={isAdmin}/>
        )
    }

    let noUser = null
    if(!props.auth.currentUser || (!props.passwordSignIn && !props.passwordSignIn.signedIn) || (!props.phoneSignIn && !props.phoneSignIn.signedIn)){
        //No Current User
        noUser = (
            <Redirect to={ROUTES.SIGN_IN}/>
        )
    }

    return (
        <div className={"account-info-container"}>

            <div className={"signIn-redirect"}>
                {noUser}
            </div>

            <div className={"account"}>
                {(!isAdmin) ? <UserAccount/> : <AdminAccount statusHandler={adminControlStatusHandler}/> }
            </div>

            {status}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        auth : state.auth,
        passwordSignIn : state.auth.passwordSignIn,
        phoneSignIn    : state.auth.phoneSignIn,
        data : state.firestore.data
    }
}

export default connect(mapStateToProps)(AccountInfo)

{/*<div className={"info-container"} id={"adminboard-info-container"}>*/}
{/*    <div className={"navigation"}>*/}
{/*        <button onClick={() => adminControlStatusHandler("users",allUsersList)}>*/}
{/*            Users*/}
{/*            <div></div>*/}
{/*        </button>*/}
{/*        <button onClick={() => adminControlStatusHandler("admins",allAdminsList)}>*/}
{/*            Admins*/}
{/*            <div></div>*/}
{/*        </button>*/}
{/*    </div>*/}
{/*</div>*/}


