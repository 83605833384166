//Base Import
import React, {useState, useEffect} from "react"
import "./PreviousStatus.css"

//Node Modules Imports
import {connect}             from "react-redux";
import {useFirestoreConnect} from "react-redux-firebase";

//Files/Component/Container Imports
import DataCard from "../../../UI/Cards/DataCard/DataCard";

//PreviousStatus Component: Shows Previous Vehicle Data Info
let PreviousStatus = (props) => {
    let [selectedTime, setSelectedTime]         = useState(null)
    let [data, setData]                         = useState(null)
    let [sortedData, setSortedData]             = useState([])
    let [selectedDayIndex, setSelectedDayIndex] = useState(props.daysCollections.length-1)
    let [dataCollection, setDataCollection]     = useState([])
    let [msgCollection, setMsgCollection]       = useState([])

    //Set Sorted Data(Vehicle Data of most recent day) from Current Mission's data-log
    useEffect(() => {

        //If Data is Loaded and data-log of Current Mission is Loaded
        if(props.data && props.currentMission && props.data['solus-lr'] && props.data['solus-lr'][props.currentMission] && props.data['solus-lr'][props.currentMission]["status"] && props.data['solus-lr'][props.currentMission]["status"]['data-log']){

            //If a specific time is selected by the user
            if(selectedTime){

                //All Days Recorded Data
                let daysData           = props.data['solus-lr'][props.currentMission]["status"]['data-log']

                //All Days Recorded of Current Mission
                let missionDaysArrays  = props.data['solus-lr'][props.currentMission]['days-recorded']
                let day                = null

                //Iterate through All days Data was recorded for Current Mission
                missionDaysArrays.map(item => {
                    //If day matches selected day
                    if(item === selectedTime.day){
                        //assign/define day
                        day = item
                    }
                })

                //If selected Day data is loaded
                if(daysData[day]) {

                    //Converting Objects of objects to Array of objects
                    let daysDataArray = Object.values(daysData[day]).map((data, index) => {

                        //Check if any hour is selected
                        if (selectedTime.hour) {

                            //Define Hour, Min resp.
                            let hour = data.entry.data.timeDataSend.HH
                            let min = data.entry.data.timeDataSend.MN

                            //Check if both hour and time match
                            if (selectedTime.hour === hour && selectedTime.min === min) {
                                return data
                            }
                            //Check if only hour match but not min
                            else if (selectedTime.hour === hour && !selectedTime.min) {
                                return data
                            }
                            //Check otherwise
                            else {
                                return null
                            }
                        }
                        //If Hour is not selected, simply return
                        else {
                            return data
                        }
                    }).filter((location) => {
                        //Filter to remove any undefined location
                        return location != null
                    })

                    //If Selected Days Data is not defined
                    if(daysDataArray.length === 0){

                        //Set Data, No data for this day
                        setData(
                            <div className={"no-data-for-selection"}>No Data Recorded For the Current Selection</div>
                        )
                    }

                    //Set Sorted Data
                    setSortedData(
                        sortData(daysDataArray) //Sort data by Time
                    )
                }
                else {
                    //Selected Day data is not loaded
                    setData(
                        <div className={"data-loading"}>Loading...</div>
                    )
                }
            }
        }
    }, [props.data, props.currentMission, selectedTime])

    //Set Data Cards of vehicle data
    useEffect(() => {

        //Sorted Data is defined
        if(sortedData.length !== 0){
            setData (
                <DataCard
                    day            = {selectedTime.day}
                    data           = {sortedData}
                    status         = {"previous-status"} //since coming from PreviousStatus Component
                    selectedTime   = {selectedTime}
                    trackerStatus  = {"Previous"}
                    missionLocationArray  = {[]}
                    showPrediction = {false}
                    currentMission = {props.currentMission}
                    mapDataHandler = {props.mapDataHandler}/> //Function to set MapDataInfo
            )
        }
    }, [props.currentMission, props.mapDataHandler, selectedTime, sortedData])

    //Get Firestore Collection Query for data-log/msg-log for the current mission & Selected day
    let getCollectionQuery = (type) => {
        return {
            collection: 'solus-lr',
            doc: props.currentMission,
            subcollections: [
                {
                    collection: "status",
                    doc : type
                },
                {
                    collection: props.daysCollections[selectedDayIndex]
                }]
        }
    }

    //Set Vehicle data-logs/msg-log Firestore Collection Query for the current mission of selected day
    useEffect(() => {

        //If Current Mission is defined and Days of data recorded for the Current Mission is defined.
        if(props.currentMission && props.daysCollections) {

            //Set Vehicle data-logs/msg-log Firestore Collection Query for the current mission of selected day
            setDataCollection(getCollectionQuery("data-log"))
            setMsgCollection(getCollectionQuery("msg-log"))
        }
    }, [selectedDayIndex])

    //Get Vehicle data-log/msg-log for the current mission of the selected day
    useFirestoreConnect(() => dataCollection)
    useFirestoreConnect(() => msgCollection)

    //Sort Data based on Time
    let sortData = (daysData) => {
        return (
            daysData
                .sort((entry1, entry2) => {
                    return entry2.entry.sec - entry1.entry.sec
                })
                //LAT,LNG 0 values issue Fixed
                // .filter(element => {
                //     //TODO SINCE RECEIVING ONLY GPS DATA, WHEN VEHICLE DIVES, I GET LAT,LNG 0. To avoid showing on map we filter it, has to be removed later
                //     return (element.entry.data.Latitude !== "0.000000" && element.entry.data.Longitude !== "0.000000")
                // })
        )
    }

    //Set Selected Time
    let selectedTimeHandler = () => {

        //Set Data to "Loading" while time is being set
        setData(
            <div className={"data-cards-loading"}>Loading...</div>
        )

        //Get Selected Day, Hour, Min from the Selectors
        let day       = document.getElementById("day-select")
        let hour      = document.getElementById("time-select-hour")
        let min       = document.getElementById("time-select-min")

         console.log("PREVIOUS TEST 3 - ", day.value)

        //Set Selected Time
        setSelectedTime({
            day       : (day.value  === "Day") ? props.daysCollections[0] : day.value, //SET TO FIRST DAY IF NOT SELECTED
            hour      : (hour.value === "Hour" ? null : hour.value),
            min       : (min.value  === "Min"  ? null : min.value),
        })

        let day_index = (day.value === "Day") ? 0 : props.daysCollections.indexOf(day.value.toString())

        //Set Selected Day Index using selected day and list of days(daysCollection) for the current mission
        setSelectedDayIndex(day_index)
    }

    //Setup Time(Min and Hour) array for the selectors
    let min  =  Array(60).fill(0)
    let hour =  Array(24).fill(0)


    let selectors = null
    //Set Selectors(to select day and time)
    // If Days or recorded data is defined
    if(props.daysCollections.length !== 0) {

        //Set Selectors
        selectors = (
            <div className={"selectors"}>
                <select name="day-select" id={"day-select"}>
                    /*Base Value*/
                    <option value={null}>Day</option>
                    {
                        props.daysCollections.map(day => {
                            return (
                                <option key={day} value={day}>{day}</option>
                            )
                        })
                    }
                </select>

                <select name="time-select-hour" id={"time-select-hour"}>
                    /*Base Value*/
                    <option value={null}>Hour</option>
                    {
                        hour.map((item, index) => {
                            if(index < 10){
                                index = `0${index}`
                            }
                            return (
                                <option key={index} value={index}>{index}</option>
                            )
                        })
                    }
                </select>

                <select name="time-select-min" id={"time-select-min"}>
                    /*Base Value*/
                    <option value={null}>Min</option>
                    {
                        min.map((item, index) => {
                            if(index < 10){
                                index = `0${index}`
                            }
                            return (
                                <option key={index} value={index}>{index}</option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }

    return (
        <div className={"previous-status-container"}>
            <div className={"heading"}>
                <h5>
                    Select Day/Time Here for previous status history of the vehicle
                </h5>
            </div>

            <div className={"selected-time"}>
                {selectors}
                <div className={"search-button"}>
                    <button onClick={selectedTimeHandler}>
                        <i className="fas fa-search"></i>
                    </button>
                </div>
            </div>

            <div className={"data-cards"}>
                {data}
            </div>
        </div>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state, ownProps) => {
    return {
        data : state.firestore.data
    }
}

//Connect to the Redux Store State and Export the Component
export default connect(mapStateToProps)(PreviousStatus)


