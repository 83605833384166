/*==============
Component Routes
===============*/

//Landing Page Routes
export const LANDING = '/'

//Sign-In Routes
export const SIGN_IN = '/signin'

//Account Routes
export const ACCOUNT_INFO = '/accountInfo'

//Admin Routes
export const ADMIN   = '/admin'

//Password Forget Routes
export const PASSWORD_FORGET = '/pw-forget'


//New User Routes
export const NEW_USER = '/user-email'

//Admin DashBoard Routes
export const DASHBOARD     = '/dashboard'

//User DashBoard Routes
export const USER_DASHBOARD = '/userdashboard'

//Admin DashBoard Routes
export const ADMIN_DASHBOARD = '/admindashboard'

/*====================
Cellula Website Routes
====================*/

//About Us Routes
export const ABOUT = 'https://www.cellula.com/about-cellula'
export const ABOUT_ROUTES = [
    {name : "About Cellula",         val : "https://www.cellula.com/about-cellula"},
    {name : "Upcoming Events",       val : "https://www.cellula.com/upcoming-events"},
    {name : "HSQE",                  val : "https://www.cellula.com/hsqe"},
    {name : "Careers/Hiring",        val : "https://www.cellula.com/careers-hiring"},
    {name : "Customers",             val : "https://www.cellula.com/customers"},
    {name : "Journal Contributions", val : "https://www.cellula.com/journal-contributions"}
]

//Contact Us Routes
export const CONTACT = 'https://www.cellula.com/contact'

//Home Routes
export const HOME    = 'https://www.cellula.com/'

//News Routes
export const NEWS    = 'https://www.cellula.com/news'

//Auth Routes
export const AUTH_SERVER_LESS = "https://us-central1-solus-lr-website.cloudfunctions.net/app"

//UDP Routes
export const UDP_SERVER_LESS = "https://us-central1-solus-lr-website.cloudfunctions.net/app"
