//Base Import
import React, {useState, useEffect} from "react"
import "./CurrentStatus.css"

//Node Modules Imports
import {connect} from "react-redux"

//Files/Component/Container Imports
import DataCard from "../../../UI/Cards/DataCard/DataCard";

//CurrentStatus Component: Shows Current Vehicle Data Info
let CurrentStatus = (props) => {

    let [data, setData]                                   = useState(null)
    let [sortedData, setSortedData]                       = useState([])
    let [trackerStatus, setTrackerStatus]                 = useState(null)
    let [missionLocationsArray, setMissionLocationsArray] = useState([])
    let [showPrediction, setShowPrediction]               = useState(null)

    //SetInterval to Update Tracker Status every given seconds
    useEffect(() => {
        let interval = setInterval(() => {
            setTrackerStatus("Updating Status")
            setDataCards()
        }, 30000)
        return () => clearInterval(interval);
    }, [])

    //Set Sorted Data(Vehicle Data of most recent day) from Current Mission's data-log
    useEffect(() => {

        //If Data is Loaded and data-log of Current Mission is Loaded
        if(props.data && props.currentMission && props.data['solus-lr'] && props.data['solus-lr'][props.currentMission] && props.data['solus-lr'][props.currentMission]['days-recorded'] && props.data['solus-lr'][props.currentMission]["status"] && props.data['solus-lr'][props.currentMission]["status"]['data-log']) {

            //All Days Recorded Data
            let daysData           = props.data['solus-lr'][props.currentMission]["status"]['data-log']

            //All Days Recorded of Current Mission
            let missionDaysArrays  = props.data['solus-lr'][props.currentMission]['days-recorded']

            //Most Recent Day of recorded Data
            let day                = missionDaysArrays[missionDaysArrays.length - 1]

            //If Data of most recent day is defined/loaded
            if(daysData[day]){

                //Converting Objects of objects to Array of objects
                let daysDataArray = Object.values(daysData[day])

                //Set Vehicle Data of Most recent Day after Sorting Data by Time
                setSortedData(
                    sortData(daysDataArray)
                )
            }
            else {
                //If Data of most recent day is not defined/loaded
                //Show "Loading" to user
                setData(
                    <div className={"data-loading"}>Loading...</div>
                )
            }
        }
    }, [props.data, props.currentMission])

    //Set Mission Locations Array and Show Prediction Enabled from Mission File Log
    useEffect(() => {

        //console.log("MISSION ARRAY - data changed recorded", trackerStatus)

        //Set Mission Locations Array
        if(props.data && props.currentMission && props.data['solus-lr'] && props.data['solus-lr'][props.currentMission] && props.data['solus-lr'][props.currentMission]["status"] && props.data['solus-lr'][props.currentMission]["status"]['mission-file-log']){

            if(props.data['solus-lr'][props.currentMission]['mission-file-days-recorded']){

                //All Days Recorded of Current Mission
                let missionDaysArrays  = props.data['solus-lr'][props.currentMission]['mission-file-days-recorded']

                //Most Recent Day of recorded Data
                let day                = missionDaysArrays[missionDaysArrays.length - 1]

                let missionFileDay     = props.data['solus-lr'][props.currentMission]["status"]['mission-file-log'][day]

                //If Mission File day exists
                if(missionFileDay){

                    console.log("MISSION FILE - mission file exists")

                    let daysMissionFile    = props.data['solus-lr'][props.currentMission]["status"]['mission-file-log'][day]['mission-file']

                    //Most Recent data entry first
                    let missionFileData    = Object.values(daysMissionFile).reverse()

                    let data_array         = missionFileData[0].data_array

                    console.log("MISSION FILE - setting mission mission file array", data_array)

                    setMissionLocationsArray(
                        sortMissionData(data_array)
                    )

                    //Get Mission File status-enabled (changed by user to switch mission prediction on/off)
                    let missionFileStatus = (props.data['solus-lr'][props.currentMission]["status"]['mission-file-log']['status_enabled'])

                    console.log("MISSION FILE - setting show prediction", missionFileStatus && (data_array.length !== 0))

                    //Set Prediction to true if the Mission File Data exists and Status-enabled is true
                    setShowPrediction(
                        missionFileStatus && (data_array.length !== 0)
                    )
                }
            }
        }
    }, [props.data, props.currentMission, showPrediction])

    //Set Tracker Status and Data Cards of vehicle data
    useEffect(() => {

        //If Sorted Data(Vehicle Data of most recent day) is defined
        if(sortedData.length !== 0){

            //Most Recent Day Data
            let firstDayData     = sortedData[0]

            //Most Recent Day Time, Hour, Min resp.
            let firstDayDataTime = firstDayData.entry.data.timeDataSend
            let firsDayHour      = parseInt(firstDayDataTime.HH)
            let firstDayMin      = parseInt(firstDayDataTime.MN)

            let date             = new Date()
            let currentTime = {
                HH: date.getHours(),
                MN: date.getMinutes()
            }

            //Current and Predicted should be like 10 apart,
            // so Tracker Status is "Current" when minutes is within 10 mins.//TODO TESTING - change to 10 mins
            if(currentTime.HH === firsDayHour && (currentTime.MN >= firstDayMin-1 && currentTime.MN <= firstDayMin+1)){

                //console.log("MISSION ARRAY - setting tracker status and data cards")

                setTrackerStatus("Current")

                //Call Set Data Cards
                setDataCards()
            }
            else {

                //Tracker Status is "Predicted" when minutes is not within 10 mins.
                setTrackerStatus("Predicted")

                //Call Set Data Cards
                setDataCards()
            }
        }
    }, [sortedData, trackerStatus])

    //Set Data to Data Cards that show vehicle data
    let setDataCards = () => {

        //If TrackerStatus is defined
        if(trackerStatus){

            //Data is set as DataCard Component
            setData (
                <DataCard
                    day                   = {sortedData[0].entry.day} //First Day Name
                    data                  = {sortedData} //Latest Day Data(Vehicle Data of most recent day)
                    status                = {"current-status"} //Since coming from CurrentStatus Component
                    trackerStatus         = {trackerStatus}
                    currentMission        = {props.currentMission}
                    mapPredictionDataInfo = {props.mapPredictionDataInfo}
                    missionLocationArray  = {(showPrediction) ? missionLocationsArray : []}
                    showPrediction        = {showPrediction}
                    mapDataHandler        = {props.mapDataHandler}/> //Function to set MapDataInfo
                )
        }
    }

    //Sort Data by Time
    let sortData = (daysData) => {
        //console.log("MISSION ARRAY - sorting data array")
        return (
            daysData
                .sort((entry1, entry2) => {
                    return entry2.entry.sec - entry1.entry.sec
                })
                //LAT,LNG 0 values issue Fixed
                //
                // .filter(element => {
                //     //SINCE RECEIVING ONLY GPS DATA,
                //     // WHEN VEHICLE DIVES, I GET LAT,LNG 0. To avoid showing on map we filter it, has to be removed later.
                //     return (element.entry.data.Latitude !== "0.000000" && element.entry.data.Longitude !== "0.000000")
                // })
        )
    }

    let sortMissionData = (dataArray) => {
        console.log("MISSION ARRAY - sorting mission array", dataArray)
        return (
            dataArray
                .sort((point_1, point_2) => {
                    return point_1.index - point_2.index
                })
        )
    }

    if(trackerStatus && trackerStatus === "Predicted"){
        //Modify/Append SortedData with predicted data based on Mission File
        //While the Map will display Mission File Data(Locations - lat, lon)
    }

    return (
        <div className={"current-status-container"}>
            <div className={"data-cards"}>
                {data ? data : <div className={"data-cards-loading"}>Loading...</div>}
            </div>
        </div>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state, ownProps) => {
    return {
        data : state.firestore.data
    }
}

//Connect to the Redux Store State and Export the Component
export default connect(mapStateToProps)(CurrentStatus)