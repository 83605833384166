//Base Import
import React, {useState, useEffect} from "react"
import "./MapInfo.css"

//Node Modules Imports

//Files/Component/Container Imports
import * as DATE from "../../../constants/date/date"


//MapInfo Component: Shows Map Info based on Selected Status(Current or Previous status)
let MapInfo = (props) => {

    //Get Current Time
    let getCurrentTime  = (type) => {
        let currentTime = new Date()

        if(type === "string"){
            let hour = (currentTime.getHours().toString().trim())
            let min  = currentTime.getMinutes().toString().trim()

            return (
                ((hour<10) ? `0${hour}` : hour) + ":" +
                ((min<10)  ? `0${min}`  : min)  + " PST"
            )
        }
        return null
    }

    let status = null
    let day    = null

    //If mapData is Loaded (coming from DataInfo Card Component)
    if(props.mapData){

        console.log("MAP DATA INFO", props.mapData)

        //If Status is Previous Status
        if (props.mapData.status && props.mapData.status === "previous-status"){
            status = "Previous Status"
            day    = null
        }

        //If Status is Current Status
        else if (props.mapData.status && props.mapData.status === "current-status"){
            //Set Status as Tracker Status
            status = props.mapData.trackerStatus

            //If Status is Current
            if(status === "Current"){
                //Set Day from mapData
                day    = props.mapData.day
            }
            //If Status is Predicted
            if(status === "Predicted"){

                //Set Day as New Day
                let currentDay = new Date()
                day    = `${DATE.WEEK_DAYS[currentDay.getDay()-1]}, ${currentDay.getDate()} ${DATE.YEAR_MONTHS[currentDay.getMonth()]} ${currentDay.getFullYear()}`
            }
        }
    }

    return (
        <div className={"map-info-container"}>
            <div className={"mission-status"}>
                <h5>
                    Mission Status
                </h5>
            </div>

            <div className={"main-info"}>
                <div className={"current-mission-name"}>
                    <h6 className={"sub-heading"}>Current Mission: </h6>
                    <h6 className={"sub-value"}>
                        {props.currentMission ? props.currentMission : null}
                    </h6>
                </div>

                <div className={"current-mission-time"}>
                    <h6 className={"sub-heading"}>Current Time: </h6>
                    <h6 className={"sub-value time"}>
                        {getCurrentTime("string")}
                    </h6>
                    <h6 className={"sub-value day"}>
                        {day}
                    </h6>
                </div>

                <div className={"tracker-status"}>
                    <h6 className={"sub-heading"}>Tracker Status: </h6>
                    <h6 className={"sub-value"}>
                        {status}
                    </h6>
                </div>

                <div className={"last-communication"}>
                    <h6 className={"sub-heading"}>Last Communication: </h6>
                    <h6 className={"sub-value time"}>
                        {props.mapData && props.mapData.locationsArray && props.mapData.locationsArray[0].time ? props.mapData.locationsArray[0].time.HH + ":" + props.mapData.locationsArray[0].time.MN + ":" +  props.mapData.locationsArray[0].time.SS + " PST"  : null}
                    </h6>
                    <h6 className={"sub-value"}>
                        {(props.mapData && props.mapData.day) ? props.mapData.day : null}
                    </h6>
                </div>
            </div>

            <div className={"data-info"}>
                <div className={"heading"}>
                    <h6 className={"sub-heading"}> Heading: </h6>
                    <h6 className={"sub-value"}>
                        {(props.mapData && props.mapData.Heading) ? Math.ceil(parseFloat(props.mapData.Heading)) : null}
                        <span className={"unit"}>&#176;</span>
                    </h6>
                </div>

                <div className={"surge-vel"}>
                    <h6 className={"sub-heading"}> Speed: </h6>
                    <h6 className={"sub-value"}>
                        {(props.mapData && props.mapData.SurgeVel) ? parseFloat(props.mapData.SurgeVel).toFixed(2) : null}
                        <span className={"unit"}>m/s</span>
                    </h6>
                </div>

                <div className={"altitude"}>
                    <h6 className={"sub-heading"}> Altitude: </h6>
                    <h6 className={"sub-value"}>
                        {(props.mapData && props.mapData.Altitude) ? parseFloat(props.mapData.Altitude).toFixed(0) : null}
                        <span className={"unit"}>m</span>
                    </h6>
                </div>
                <div className={"depth"}>
                    <h6 className={"sub-heading"}> Depth: </h6>
                    <h6 className={"sub-value"}>
                        {(props.mapData && props.mapData.Depth) ? parseFloat(props.mapData.Depth).toFixed(1) : null}
                        <span className={"unit"}>m</span>
                    </h6>
                </div>


                <div className={"status"}>
                    <h6 className={"sub-heading"}> Charge Remaining: </h6>
                    <h6 className={"sub-value"}>
                        {(props.mapData && props.mapData.ChargeRemaining) ? parseFloat(props.mapData.ChargeRemaining).toFixed(1) : "n/a"}
                    </h6>
                </div>

                <div className={"battery"}>
                    <h6 className={"sub-heading"}> State of Charge: </h6>
                    <h6 className={"sub-value"}>
                        {(props.mapData && props.mapData.StateOfCharge) ? parseFloat(props.mapData.StateOfCharge).toFixed(1) : "n/a"}
                    </h6>
                </div>

            </div>

        </div>
    )
}

//Export Component
export default MapInfo