//Base Import
import React, {useState, useEffect} from "react"
import "./MapContainer.css"

//Node Modules Imports
import {compose}                                             from "redux";
import {connect}                                             from "react-redux"
import {Map, Marker, InfoWindow, Polyline, GoogleApiWrapper} from "google-maps-react"

//Files/Component/Container Imports

//MapContainer Component: Shows Map with Vehicle Location Info
let MapContainer = (props) => {

    //Most Recent Received Current Data
    let [mapData, setMapData] = useState({
        lng      : -122.987560, //Cellula Office Lng
        lat      : 49.199810,   //Cellula Office Lat
        surgeVel : null,
        heading  : null,
        altitude : null,
        depth    : null
    })

    // let [locationsArray, setLocationsArray]         = useState([])
    // let [locationsMarkers, setLocationsMakers]      = useState(null)

    let [currentLocationsArray, setCurrentLocationsArray]                 = useState([])
    let [currentLocationsMarkers, setCurrentLocationsMarkers]             = useState(null)
    let [currentLocationsPolyLine, setCurrentLocationsPolyLine]           = useState(null)

    let [predictionLocationsArray, setPredictionLocationsArray]           = useState([])
    let [predictionLocationsMarker, setPredictionLocationsMarker]         = useState(null)
    let [predictionLocationsPolyLine, setPredictionLocationsPolyLine]     = useState(null)

    let [missionLocationsArray, setMissionLocationsArray]                 = useState([])
    let [missionLocationsPolyLine, setMissionLocationsPolyLine]           = useState(null)

    let [currentToPredictionPolyLine, setCurrentPredictionPolyLine]       = useState([])

    let [activeMarkerInfo, setActiveMarkerInfo]                           = useState(null)
    let [activeMarker, setActiveMarker]                                   = useState(null)
    let [showingInfoWindow, setShowingInfoWindow]                         = useState(null)

    let [solusMarkerImage, setSolusMarkerImage]                           = useState(`SOLUS_LR_MARKER_0deg.png`)
    let [markerSize, setMarkerSize]                                       = useState({width : 30, height : 90})
    let [openMarkerInfo, setOpenMarkerInfo]                               = useState(null)

    //Set Map Data, Marker Image, Current, Prediction & Mission Locations Array and Show Prediction status
    useEffect(() => {

        //If Map Data is defined, Set Current, Prediction and Mission Locations Array and Solus Marker Image
        if(props.mapData){

            console.log("MISSION ARRAY - ", props.mapData.Heading)

            //Set Map Data
            setMapData({
                lat      : parseFloat(props.mapData.Latitude),  //Lat of Last(most recent) Data Instant
                lng      : parseFloat(props.mapData.Longitude), //Lng of Last(most recent) Data Instant
                surgeVel : props.mapData.SurgeVel,              //SurgeVel of Last(most recent) Data Instant
                heading  : props.mapData.Heading,               //Heading of Last(most recent) Data Instant
                altitude : props.mapData.Altitude,              //Altitude of Last(most recent) Data Instant
                depth    : props.mapData.Depth                  //Depth of Last(most recent) Data Instant
            })

            //Get Heading of Last(most recent) Data Instant
            let heading = parseFloat(props.mapData.Heading)

            //Set Marker Image based on Heading,
            // Taking default origin (0 deg points to North) of Heading
            setSolusMarkerImage(
                calculateHeadingImage(heading)
            )

            //Set Locations Array of the Selected Day
            setCurrentLocationsArray(props.mapData.locationsArray)
            setPredictionLocationsArray(props.mapData.predictionArray)
            setMissionLocationsArray(props.mapData.missionLocations)

        }
    }, [props.mapData, props.data])

    //Set Markers Here
    useEffect(() => {

        if(props.mapData){
            //console.log("MAP CONTAINER - SETTING MARKERS - ", props.mapData.trackerStatus, currentLocationsArray, predictionLocationsArray, missionLocationsArray)
            console.log("MISSION ARRAY - data received at MAP CONTAINER", predictionLocationsArray, missionLocationsArray)
        }

        if(props.mapData && (props.mapData.trackerStatus === "Current" || props.mapData.trackerStatus === "Previous") && currentLocationsArray.length !== 0) {

            //Prediction Array's definition when the tracker status is current or previous
            //Prediction array holds elements that are yet to be covered by the vehicle i.e. missionLocationsArray[index] where index >= npp

            //Set Markers
            setCurrentLocationsMarkers(
                setMarkers(props.mapData.trackerStatus, currentLocationsArray, `map-marker.svg`)
            )

            setPredictionLocationsMarker(
                setMarkers(props.mapData.trackerStatus, predictionLocationsArray, `map-marker-predicted.svg`)
            )

            //Set PolyLines
            setCurrentLocationsPolyLine(
                setPolyLines(props.mapData.trackerStatus, currentLocationsArray)
            )

            setPredictionLocationsPolyLine(
                setPolyLines(props.mapData.trackerStatus, predictionLocationsArray)
            )

            setCurrentPredictionPolyLine(
                setPolyLines(props.mapData.trackerStatus, [currentLocationsArray[0], predictionLocationsArray[0]])
            )
        }

        if(props.mapData && props.mapData.trackerStatus === "Predicted" && missionLocationsArray.length !== 0){

            //Prediction Array's definition when the tracker status is predicted
            //Prediction array holds elements that are already covered/traveled to/passed by the vehicle based on time estimation i.e. missionLocationsArray[index] where index >= npp and index <= time elapsed based index

            //Set Location Markers(Passed Locations)
            let currentMarkers    = setMarkers(props.mapData.trackerStatus, currentLocationsArray.slice(1, currentLocationsArray.length - 1).concat(predictionLocationsArray), `map-marker.svg`)
            let lastCurrentMarker = setMarkers(props.mapData.trackerStatus, [currentLocationsArray[0]], `map-marker-last-current.svg`)

            //Set Current Markers and Polylines
            setCurrentLocationsMarkers(currentMarkers.concat(lastCurrentMarker))
            setCurrentLocationsPolyLine(
                setPolyLines(props.mapData.trackerStatus, currentLocationsArray)
            )

            //None of the Prediction Locations have been passed/reached based on Time
            if(predictionLocationsArray.length === 0){

                //Since time estimation array size is always in relation to the npp
                //in cases where npp === 0, polyline and markers can be complete mission locations array, as the current position is very far
                //in cases where npp  >= 0, polyline and markers should only be positions after npp.

                let start_index = (props.mapData && props.mapData.timeEstimateArray && props.mapData.timeEstimateArray.length !== missionLocationsArray.length) ? props.mapData.timeEstimateArray.length : 0
                let end_index   = missionLocationsArray.length

                if(props.mapData && props.mapData.timeEstimateArray){
                    if(props.mapData.timeEstimateArray.length === 1){
                        start_index = missionLocationsArray.length - 1
                    }
                }

                setPredictionLocationsMarker(
                    setMarkers(props.mapData.trackerStatus, missionLocationsArray.slice(start_index, end_index), `map-marker-predicted.svg`)
                )

                setPredictionLocationsPolyLine(null)

                setMissionLocationsPolyLine(
                    setPolyLines(props.mapData.trackerStatus, missionLocationsArray.slice(start_index, end_index))
                )

                setCurrentPredictionPolyLine(
                    setPolyLines(props.mapData.trackerStatus, [currentLocationsArray[0], missionLocationsArray.slice(start_index, end_index)[0]])
                )
            }
            else {


                //Find the Part of Mission Locations that is yet to be covered by the vehicle i.e. mission locations without prediction locations
                let prediction_index_start = null
                let prediction_index_end   = missionLocationsArray.length

                for(let index = 0; index < missionLocationsArray.length; index++){
                    let lat_1 = predictionLocationsArray[predictionLocationsArray.length - 1].lat
                    let lng_1 = predictionLocationsArray[predictionLocationsArray.length - 1].lng

                    let lat_2 = missionLocationsArray[index].lat
                    let lng_2 = missionLocationsArray[index].lng

                    if(lat_1 === lat_2 && lng_1 === lng_2){
                        prediction_index_start = index
                        index = missionLocationsArray.length - 1
                    }
                }

                console.log("MISSION ARRAY - setting marker arrays for prediction ", prediction_index_start, prediction_index_end, missionLocationsArray.slice(prediction_index_start + 1, prediction_index_end), predictionLocationsArray)

                if(prediction_index_start !== null){//since value could be 0 and 0 is falsy

                    setPredictionLocationsMarker(
                        setMarkers(props.mapData.trackerStatus, missionLocationsArray.slice(prediction_index_start + 1, prediction_index_end), `map-marker-predicted.svg`)
                    )

                    setPredictionLocationsPolyLine(
                        setPolyLines(props.mapData.trackerStatus, predictionLocationsArray)
                    )

                    setMissionLocationsPolyLine(
                        setPolyLines(props.mapData.trackerStatus, [predictionLocationsArray[predictionLocationsArray.length - 1]].concat(missionLocationsArray.slice(prediction_index_start + 1, prediction_index_end)))
                    )

                    setCurrentPredictionPolyLine(
                        setPolyLines(props.mapData.trackerStatus,[currentLocationsArray[0], predictionLocationsArray[0]])
                    )
                }
            }
        }
    }, [props.mapData, currentLocationsArray, predictionLocationsArray, missionLocationsArray])

    //Return Markers
    let setMarkers = (trackerStatus, locationsArray, marker_image) => {

        console.log("MAP CONTAINER - SETTING MARKERS - func", trackerStatus, locationsArray, marker_image)

        return (
            locationsArray
                .map((location, index) => {
                    //If Location Instant matches the Location of Last(most recent) Data Instant
                    if (location.lat === mapData.lat && location.lng === mapData.lng) {

                        //Return null,
                        // since Last(most recent) Data Instant has a SOLUS-LR image marker
                        return null;
                    }

                    //Blue Dot Marker with Black Outline
                    return (
                        <Marker
                            key={index}
                            onClick={onMarkerClick}
                            icon={{
                                url: require(`../../../assets/images/${marker_image}`),
                                scaledSize: new props.google.maps.Size(10, 10),
                                origin: new props.google.maps.Point(0, 0), // origin
                                anchor: new props.google.maps.Point(4, 5) // anchor
                            }}
                            position={{
                                lat: location.lat,
                                lng: location.lng
                            }}>
                        </Marker>
                    )
                })
                .filter((location) => {
                    return location != null
                })
        )
    }

    //Return Polylines
    let setPolyLines = (trackerStatus, locationsArray) => {
        return (
            <Polyline path    = {locationsArray}
                      options = {{
                          strokeOpacity : 0,
                          icons         : [{
                              //Dotted Line
                              icon   : {
                                  offset        : '0',
                                  path          : 'M 0,-1 0,1',
                                  strokeColor   : '#282c34',
                                  strokeWeight  : 2,
                                  strokeOpacity : 1
                              },
                              scale  : 4,
                              repeat : '10px'
                          }],
                      }}/>
        )
    }

    //Get Correct Solus-LR Image Corresponding to the Heading
    let calculateHeadingImage = (heading) => {

        //Lots of Math,
        //Basic Idea - 360 deg is equally divided into 32 parts of 11.25 deg
        //           - Each quadrant has 8 parts of 11.25 deg each
        //           - Heading is calculated based on this division.
        //           - Draw on paper to understand better, Take it easy.
        let val1 = 11.25 * Math.ceil(heading/11.25) - heading
        let val2 = 11.25 * Math.floor(heading/11.25) - heading
        let minDiff = Math.min(Math.abs(val1), Math.abs(val2))
        let final = 0;
        (Math.abs(val1) === minDiff) ? final = val1 : final = val2;

        //Safety Measure
        if(heading >= 360 || (final + heading === 360)){
            heading = 0
            final = 0
        }

        //Calculate Marker size
        let deg = final + heading
        if(deg === 0 || deg === 180){
            setMarkerSize({width : 30, height: 90})
        }
        else if(deg === 90 || deg === 270){
            setMarkerSize({width : 90, height: 30})
        }
        else {
            setMarkerSize({width : 60, height: 60})
        }

        return `SOLUS_LR_MARKER_${final + heading}deg.png`
    }

    //Marker Click Handler
    let onMarkerClick = (markerData, marker, e) => {

        //Set Active Marker and Show Info Window, Set Marker Info Data
        setActiveMarker(marker)
        setShowingInfoWindow(true)
        setActiveMarkerInfo(markerData)

        if(props.mapData && props.mapData.trackerStatus && props.mapData.timeEstimateArray){

            //If the Tracker Status is Current
            if(props.mapData.trackerStatus === "Current"){

                //Map Current and Prediction Array
                let element_current = findMatchingMarker(currentLocationsArray, markerData.position.lat, markerData.position.lng)
                let element_prediction = findMatchingMarker(predictionLocationsArray, markerData.position.lat, markerData.position.lng)

                //Set Open MarkerInfo
                if(element_current){
                    setOpenMarkerInfo(element_current.element)
                }
                else {
                    setOpenMarkerInfo(element_prediction.element)
                }
            }
            if(props.mapData.trackerStatus === "Previous"){

                //Map Current Array
                let element_current = findMatchingMarker(currentLocationsArray, markerData.position.lat, markerData.position.lng)

                if(element_current){
                    setOpenMarkerInfo(element_current.element)
                }
            }
            if(props.mapData.trackerStatus === "Predicted"){

                //Map Current and Prediction Array
                let element_current = findMatchingMarker(currentLocationsArray, markerData.position.lat, markerData.position.lng)
                let element_prediction = findMatchingMarker(predictionLocationsArray, markerData.position.lat, markerData.position.lng)
                let element_mission = findMatchingMarker(missionLocationsArray, markerData.position.lat, markerData.position.lng)

                //Set Open MarkerInfo
                if(element_current){
                    setOpenMarkerInfo(element_current.element)
                }
                if(element_prediction){
                    setOpenMarkerInfo(element_prediction.element)
                }
                if(element_mission) {
                    // let npp = predictionLocationsArray.length //test only
                    // let time = props.mapData.timeEstimateArray[element_mission.index - npp].time_estimate
                    let time = findMatchingMarker(props.mapData.timeEstimateArray, markerData.position.lat, markerData.position.lng).element.time_estimate
                    let element = {
                        ...element_mission.element,
                        time : time
                    }
                    setOpenMarkerInfo(element)
                }
            }
        }
    }

    let findMatchingMarker = (locationsArray, markerDataLat, markerDataLng) => {

        for(let index = 0; index < locationsArray.length; index++){

            console.log("MARKER INFO - element on click", locationsArray[index])

            let element = locationsArray[index]

            if(element.lat === markerDataLat && element.lng === markerDataLng){
                return {"element": element, "index": index}
            }
        }

        return null
    }

    //Map Click Anywhere Handler
    let onMapClicked = () => {

        //If InfoWindow is open
        if(showingInfoWindow){
            setActiveMarker(null)
            setShowingInfoWindow(false)
            setActiveMarkerInfo(null)
            setOpenMarkerInfo(null)
        }
    }

    //Map Styles(CSS)
    let mapStyles = {
        'borderRadius' : "0.7rem",
        "boxShadow"    : "0 3px 7px rgba(0, 0, 0, 0.2)",
        "left"         : "22%"
    }

    return (
        <Map
            onClick      = {onMapClicked}
            className    = {"vehicle-map"}
            google       = {props.google}
            zoom         = {15}
            style        = {mapStyles}
            center       = {{ lat: mapData.lat, lng: mapData.lng}}
            initialCenter= {{ lat: mapData.lat, lng: mapData.lng}}>

            {currentLocationsMarkers}
            {currentLocationsPolyLine}

            {predictionLocationsMarker}
            {predictionLocationsPolyLine}

            {missionLocationsPolyLine}

            {currentToPredictionPolyLine}

            <Marker
                name    = {"vehicle-marker"}
                onClick = {onMarkerClick}
                icon    = {{
                    url        : require('../../../assets/images/' + solusMarkerImage),
                    scaledSize : new props.google.maps.Size(markerSize.width,markerSize.height),
                    anchor     : new props.google.maps.Point(35,10), // anchor
                    rotation   : 25
                    //origin: new props.google.maps.Point(0,0), // origin
                }}
                opacity={(props.mapData && props.mapData.data_status && props.mapData.data_status === "Predicted") ? 0.7 : 1}
                position= {{
                    lat: mapData.lat,
                    lng: mapData.lng
                }} />

            <InfoWindow
                marker  = {activeMarker}
                onClose = {onMapClicked}
                visible = {showingInfoWindow}>
                <div className={"info-window-container"}>
                    <div className={"location"}>
                        <i className="fas fa-map-marker-alt"></i>
                        <div className={"latitude"}>
                            {(activeMarkerInfo) ? parseFloat(activeMarkerInfo.position.lat).toFixed(2) : null}
                        </div>
                        <div className={"comma"}>
                            ,
                        </div>
                        <div className={"longitude"}>
                            {(activeMarkerInfo) ? parseFloat(activeMarkerInfo.position.lng).toFixed(2) : null}
                        </div>
                    </div>
                    <div className={"heading"}>
                        Heading: {(openMarkerInfo) ? parseFloat(openMarkerInfo.heading).toFixed(2) : null}
                        <span className={"unit"}>&#176;</span>
                    </div>
                    <div className={"time"}>
                        Time: {(openMarkerInfo) ?  `${openMarkerInfo.time.HH}:${openMarkerInfo.time.MN} PST` : null }
                    </div>
                </div>
            </InfoWindow>
        </Map>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state, ownProps) => {
    return {
        data : state.firestore.data
    }
}

//Connect Redux Store, Connect Google Api Map and Export Component
export default
compose(
    connect(mapStateToProps),
    GoogleApiWrapper(
        {
            apiKey : "AIzaSyCT8-jzJxVKL3-tDOSS28M_ajl4my6oiu0"
        }))
(MapContainer)