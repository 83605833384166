//Base Imports
import React, {useEffect, useState} from 'react';
import './Navigation.css';

//Node Modules Imports
import {Link} from "react-router-dom";
import {connect} from "react-redux"

//Files/Components/Containers Imports
import * as ROUTES from "../../../constants/routes/routes"
import DropDown from "../DropDown/DropDown";
import {signOut} from "../../../store/actions/authActions";
import BackDrop from "../BackDrop/BackDrop";

//Navigation Component: Shows the NavBar to the user
let Navigation = (props) => {

    let [backDropStatus, setBackdropStatus] = useState(false)
    let [userStatus, setUserStatus]         = useState(
        <div className={"sign-in-link"}>
            <Link to={ROUTES.SIGN_IN}>
                Sign In
            </Link>
        </div>
    )

    //Can Add more options later
    const userIconDropDownVals = [
        {type:  "link",   name : "Account",    value : "Account",  to : ROUTES.ACCOUNT_INFO},
        {type : "button", name : "userStatus", value : "Sign-Out", clickHandler : () => props.signOut()}
    ]

    //Set User Status based on Login status
    useEffect(() => {

        //If Password signed in and Phone signed in
        if(props.passwordSignIn && props.passwordSignIn.signedIn && props.phoneSignIn && props.phoneSignIn.signedIn){

            //Set User Status
            setUserStatus(
                <div className={"user-icon"}>
                    <DropDown
                        name={"user-icons"}
                        value={userIconDropDownVals}>
                        <i className="fas fa-user"></i>
                        <i className="fas fa-angle-down"></i>
                    </DropDown>
                </div>
            )
        }
        else {
            setUserStatus(
                <div className={"sign-in-link"}>
                    <Link to={ROUTES.SIGN_IN}>
                    Sign In
                    </Link>
                </div>
            )
        }
    }, [props.phoneSignIn, props.passwordSignIn])

    //Toggles Side Nav Elements
    let sideNavToggleHandler = () => {
        let element         = document.getElementById("sideNav")
        let mainElement     = document.getElementById("main-container")
        let sideNavCloseBtn = document.getElementById("sidenav-close-toggle")
        if(element && mainElement) {
            element.classList.toggle("open-side-nav")
            mainElement.classList.toggle("side-nav-open-main")
            sideNavCloseBtn.classList.toggle("display")
            setBackdropStatus(!backDropStatus)
        } else {
            //console.log("element no exists")
        }
    }

    return (
        <div className={"navbar"}>
            <div className={"navbar-links"}>

                <div className={"navbar-logo"}>
                    <img src="//static1.squarespace.com/static/591b4a93725e254bd9bea803/t/59f0d637bce176da3ae69209/1570668269124/?format=1500w" alt="" onClick={() => {window.open("http://www.cellula.com/")}}/>
                </div>

                <BackDrop status={backDropStatus} backDropClick={sideNavToggleHandler}/>

                <ul className={"links-list"} id={"sideNav"}>
                    <li>
                        <div className={"sidenav-close-toggle"} id={"sidenav-close-toggle"}>
                            <button className={"btn"} onClick={sideNavToggleHandler}>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                    </li>
                    <li>
                        <DropDown name={"About Us"} value={ROUTES.ABOUT_ROUTES}/>
                    </li>
                    <li>
                        <a href={ROUTES.HOME} target={"_blank"}>Home</a>
                    </li>
                    <li>
                        <Link to={ROUTES.DASHBOARD}>
                            Solus-LR Tracking
                            <span style={{"font-size" : "0.5em"}}>(Beta Version)</span>
                        </Link>
                    </li>
                    <li>
                        <a href={ROUTES.NEWS} target={"_blank"}>News</a>
                    </li>
                    <li>
                        <a href={ROUTES.CONTACT} target={"_blank"}>Contact</a>
                    </li>
                    <li>
                        {userStatus}
                    </li>
                </ul>
                <div className={"sidenav-open-toggle"}>
                    <button className={"btn"} onClick={sideNavToggleHandler}>
                        <i className="fas fa-bars"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

//Get Actions from the Redux Store
const mapDispatchToProps = (dispatch) => {
    return {
        signOut : () => dispatch(signOut())
    }
}

//Get State from the Redux Store
const mapStateToProps = (state) => {
    return {
        currentUser    : state.auth.currentUser,
        passwordSignIn : state.auth.passwordSignIn,
        phoneSignIn    : state.auth.phoneSignIn
    }
}

//Connect to the Redux Store State and Export the Component
export default connect(mapStateToProps, mapDispatchToProps)(Navigation)


