//Base Import
import React, {useState, useEffect} from "react"
import "./SignIn.css"

//Node Modules Imports
import {connect}  from "react-redux";
import Form       from "react-bootstrap/Form"
import {Redirect} from "react-router";

//Files/Component/Container Imports
import * as ROUTES                        from "../../../constants/routes/routes";
import firebase                           from "../../../config/firebase/firebase"
import {signIn, signInPhoneVerification}  from "../../../store/actions/authActions";
import PhoneVerification                  from "../../UI/Modal/PhoneVerification/PhoneVerification";


//SignIn Component: Signs In user via email,password followed by phone verification
let SignIn = (props) => {

    let [email, setEmail]                                   = useState("")
    let [password, setPassword]                             = useState("")
    let [data, setData]                                     = useState(null)
    let [userPhoneNumber, setUserPhoneNumber]               = useState("-")
    let [phoneVerModalShow, setPhoneVerModalShow]           = useState(false)
    let [recaptchaVerified, setRecaptchaVerified]           = useState(false)
    let [recaptchaVerMsg, setRecaptchaVerMsg]               = useState("")
    let [phoneCodeErrorMsg, setPhoneCodeErrorMsg]           = useState("")

    //Recaptcha Verification Window
    useEffect(() => {

        //On Component Did Mount
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'normal',
            'callback': function (response) {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                setRecaptchaVerified(true)
            },
            'expired-callback': function () {
                // Response expired. Ask user to solve reCAPTCHA again.
                setRecaptchaVerified(false)
            }
        });
        window.recaptchaVerifier.render().then(function (widgetId) {
            window.recaptchaWidgetId = widgetId;
        });

    }, [])

    //Call Phone SignIn action when the Email SignIn is Completed with Success
    useEffect(() => {

        //If SignedIn via Email and Password and staff results has been loaded(loading = false)
        if(props.passwordSignIn && props.passwordSignIn.signedIn && !props.staff.isLoading){

            if(props.staff.isStaff){
                phoneVerified()
            }
            else {
                //Call Phone Verification to Send Code to User
                props.signInPhoneVerification()

                //Save User Phone Number
                setUserPhoneNumber(props.currentUser.phoneNumber)
            }

            // //Call Phone Verification to Send Code to User
            // props.signInPhoneVerification()
            //
            // //Save User Phone Number
            // setUserPhoneNumber(props.currentUser.phoneNumber)
        }

        //Still Loading
        if(props.passwordSignIn && props.passwordSignIn.signedIn && props.staff.isLoading) {
            setPhoneCodeErrorMsg("Please wait...")
        }
    },[props.passwordSignIn, props.staff])

    //Show Phone Verification Modal
    useEffect(() => {

        //If SignedIn via Email and Password and Phone Code Sent Success with no Error
        if(props.passwordSignIn && props.passwordSignIn.signedIn && props.phoneSignIn && props.phoneSignIn.phoneCodeSent){
            setPhoneVerModalShow(true)
        }
        else {
            setPhoneVerModalShow(false)
        }

    }, [props.passwordSignIn, props.phoneSignIn])

    //Phone Code Message Error
    useEffect(() => {
        if(props.phoneSignIn && props.phoneSignIn.phoneCodeError){
            setPhoneCodeErrorMsg(props.phoneSignIn.phoneCodeError.message + " (~ 30mins)")
        }
    }, [props.phoneSignIn])

    //Email and Password Field Change Handler
    let onChangeHandler = (e) => {
        //handler for email and password input fields
        if(e.target.name === "email"){
            setEmail(e.target.value)
        }
        else {
            setPassword(e.target.value)
        }
    }

    //Redirect to Dashboard on Complete Verification(email and phone)
    let phoneVerified = () => {
        setData (
            <Redirect to={ROUTES.DASHBOARD}/>
        )
    }

    //Form Submit Handler
    let handleSubmitForm = (e) => {

        //Prevent Default
        e.preventDefault()

        //If user verified the recaptcha
        if(recaptchaVerified){

            //SignIn with email and pass
            props.signInUser({
                email : email,
                password : password
            })
            setRecaptchaVerMsg("")
        }
        else {
            //Show Recaptcha Message
            setRecaptchaVerMsg("Confirm you are not a robot by checking the container above")
        }
    }

    //Password Forgot Handler
    let handleForgotPassword = (e) => {

        //Prevent Default
        e.preventDefault()

        //TODO Direct to Password Forget, here sent an email to Admin to Change User Password or Phone Number
        setData (
            <Redirect to={ROUTES.PASSWORD_FORGET}/>
        )
    }

    //Phone Verification Modal Close Handler
    let phoneVerModalClose = () => {
        setPhoneCodeErrorMsg("")
        setPhoneVerModalShow(false)
    }

    return (
        <div className={"sign-in-container"}>
            {data}
            <Form>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        isInvalid   = {(!!(props.authError && props.authError.message.includes("email")))}
                        type        = "email"
                        placeholder = "Enter email"
                        name        = {"email"}
                        value       = {email}
                        onChange    = {onChangeHandler}/>
                    <Form.Text className="text-muted">
                        {
                            props.authError && props.authError.message.includes("email")
                                ? props.authError.message
                                : "We'll never share your email with anyone else."
                        }
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        isInvalid   = {(!!(props.authError && props.authError.message.includes("password")))}
                        type        = "password"
                        placeholder = "Password"
                        name        = {"password"}
                        value       = {password}
                        onChange    = {onChangeHandler}/>
                    <Form.Text className="text-muted">
                        {
                            props.authError && props.authError.message.includes("password")
                                ? props.authError.message
                                : ""
                        }
                    </Form.Text>
                </Form.Group>

                <Form.Group className={"recaptcha"} controlId="formBasicCheckbox">
                    {/*<Form.Check type="checkbox" label="Check me out for any terms and condition!!(Ques? Adrian)" />*/}
                    <div id={'recaptcha-container'}></div>
                    <Form.Text className="error-msg">
                        {recaptchaVerMsg}
                    </Form.Text>
                </Form.Group>

                <Form.Text className="phone-code error-msg">
                    {phoneCodeErrorMsg}
                </Form.Text>

                {/*<Form.Text className={"pw-forget"} onClick={handleForgotPassword}>*/}
                {/*    Forgot Password*/}
                {/*    <i className="far fa-question-circle"></i>*/}
                {/*</Form.Text>*/}

                <button type="submit" onClick={handleSubmitForm}>
                    Submit
                </button>

                {/*Phone Verification Modal*/}
                <PhoneVerification show={phoneVerModalShow} close={phoneVerModalClose} phoneVerified={phoneVerified} phoneNumber={userPhoneNumber}/>

            </Form>
        </div>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state) => {
    console.log(state)
    return {
        authError      : state.auth.authError,
        authToken      : state.auth.authToken,
        currentUser    : state.auth.currentUser,
        staff          : state.auth.staff,
        passwordSignIn : state.auth.passwordSignIn,
        phoneSignIn    : state.auth.phoneSignIn
    }
}

//Get Actions from the Redux Store
const mapDispatchToProps = (dispatch) => {
    return {
        signInUser              : (credentials) => dispatch(signIn(credentials)),
        signInPhoneVerification : () => dispatch(signInPhoneVerification())
    }
}

//Connect to the Redux Store State and Export the Component
export default connect(mapStateToProps, mapDispatchToProps)(SignIn)