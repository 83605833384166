const initState =  {
    projects : [
        {id : 1, title : "some title 1", content : "some content 1"},
        {id : 2, title : "some title 2", content : "some content 2"},
        {id : 3, title : "some title 3", content : "some content 3"}
    ]
}
const projectReducer = (state = initState, action) => {
    switch (action.type) {
        case "CREATE_PROJECT" :
            return {
                ...state,
                projects: [
                    ...state.projects,
                    {...action.project}
                ]
            }
        case "CREATE_PROJECT_ERROR" :
            //console.log("CREATE_PROJECT_ERROR - Error", action.error)
            return state
        default:
            return state
    }
}

export default projectReducer