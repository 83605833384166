//Base Import
import React, {useState,useEffect} from "react"
import "./MissionSelect.css"

//Node Modules Imports
import axios                 from "axios"
import {connect}             from "react-redux"
import * as ROUTES           from "../../../../constants/routes/routes"
import Button                from "react-bootstrap/Button";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

//Files/Component/Container Imports
import MissionInfoChangeModal from "../../../UI/Modal/MissionInfoChangeModal/MissionInfoChangeModal"

//MissionSelect Component: Shows Current Selected Mission and Missions that can be selected
let MissionSelect = (props) => {
    let [missionNames, setMissionNames]           = useState([])
    let [modalShow, setModalShow]                 = useState(false)
    let [missionAddInfoMsg, setMissionAddInfoMsg] = useState("")
    let [missionFileStatus, setMissionFileStatus] = useState(null)

    //Set Missions from Users Role
    useEffect(() => {

        //If Signed-In and Data is Loaded
        if(props.auth.currentUser && props.passwordSignIn && props.passwordSignIn.signedIn && props.phoneSignIn && props.phoneSignIn.signedIn){

            // //If admin
            // if(props.isAdmin && props.data.accounts.admins[props.auth.currentUser.uid][props.auth.currentUser.email]){
            //     //Set Mission names as the ones defined in user roles
            //     setMissionNames(props.data.accounts.admins[props.auth.currentUser.uid][props.auth.currentUser.email].role)
            // }
            //
            // //If not admin
            // if(!props.isAdmin && props.data.accounts.users[props.auth.currentUser.uid][props.auth.currentUser.email]){
            //     //Set Mission names as the ones defined in user roles
            //     setMissionNames(props.data.accounts.users[props.auth.currentUser.uid][props.auth.currentUser.email].role)
            // }

            if(props.data && props.currentMission && props.data['solus-lr']){

                if(props.data['solus-lr']['missions']){
                    setMissionNames(props.data['solus-lr']['missions'].titles)
                }

                if(props.data['solus-lr'][props.currentMission] && props.data['solus-lr'][props.currentMission]['status'] && props.data['solus-lr'][props.currentMission]['status']['mission-file-log']){
                    setMissionFileStatus(props.data['solus-lr'][props.currentMission]['status']['mission-file-log'].status_enabled)
                }
            }
        }
    }, [props.data, props.auth])

    let missionFileStatusHandler = (currentMissionName, newMissionFileEnabled) => {

        setMissionAddInfoMsg("Processing Request...")

        axios
            .post(ROUTES.UDP_SERVER_LESS + "/udp/missionFileStatus", {
                missionName       : currentMissionName,
                missionFileStatus : newMissionFileEnabled,
                adminToken        : props.auth.authToken,
                adminEmail        : props.auth.currentUser.email
            })
            .then(response => {
                setMissionAddInfoMsg(response.data)
            })
            .catch(error => {
                setMissionAddInfoMsg("Error - " + error)
            })
    }

    let missionAddHandler = (newMissionName, newMissionFileEnabled) => {

        setMissionAddInfoMsg("Processing Request...")

        axios
            .post(ROUTES.UDP_SERVER_LESS + "/udp/newMission", {
                missionName       : newMissionName,
                missionFileStatus : newMissionFileEnabled,
                adminToken        : props.auth.authToken,
                adminEmail        : props.auth.currentUser.email
            })
            .then(response => {
                setMissionAddInfoMsg(response.data)
            })
            .catch(error => {
                setMissionAddInfoMsg("Error - " + error.response.data)
            })
    }

    let handleClose = () => {
        setMissionAddInfoMsg("")
        setModalShow(false)
    }

    let data = null

    //Is Mission Names is defined
    if(missionNames.length !== 0){

        //Iterate through the Mission Names
        data = missionNames.map(mission => {
            let activeMission = null
            //If iterating mission is same as the current mission
            if(props.currentMission === mission){
                //Set as active mission(UI active circle)
                activeMission = (
                        <i className="far fa-check-circle"></i>
                    )
            }

            //Return a button with Mission Name and active status
            return (
                <button key={mission} onClick={() => props.setMissionHandler(mission)}>
                    {mission} {activeMission}
                </button>
            )
        })
    }

    return (
        <div className={"mission-select-container"}>
            <div className={"mission-status"}>
                <div className={"heading"}>
                    <h3>
                        Active Mission
                    </h3>
                </div>
                <div className={"active-mission"}>
                    <h6>
                        {props.currentMission}
                    </h6>
                </div>
            </div>

            <div className={"missions"}>
                {data ? data : <div className={"data-cards-loading"}>Loading...</div>}

                <MissionInfoChangeModal
                    currentMission={props.currentMission}
                    currentMissionFileStatus={missionFileStatus}
                    missionAddInfoMsg={missionAddInfoMsg}
                    show={modalShow}
                    handleClose={handleClose}
                    missionFileStatusHandler={missionFileStatusHandler}
                    missionAddHandler={missionAddHandler}/>
            </div>

            <div className={"settings-btn"}>
                <Button className={"confirm-btn"} variant="primary" onClick={() => setModalShow(true)}>
                    Mission Setting
                    <i className="fas fa-cogs"></i>
                </Button>
            </div>
        </div>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state, ownProps) => {
    return {
        auth           : state.auth,
        passwordSignIn : state.auth.passwordSignIn,
        phoneSignIn    : state.auth.phoneSignIn,
        data           : state.firestore.data,
        msg            : state.data.msg,
        error          : state.data.error,
        days           : state.data.days
    }
}

//Connect to the Redux Store State and Export the Component
export default connect(mapStateToProps)(MissionSelect)