//Base Import
import React, {useState, useEffect} from "react"
import "./AccountInfoChangeModal.css"

//Node Modules Imports
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form";

//Files/Component/Container Imports

let AccountInfoChangeModal = (props) => {

    let [newName, setNewName]               = useState("")
    let [newPhone, setNewPhone]             = useState("")
    let [cellula_staff, setCellula_staff]   = useState("")

    useEffect(() => {
        if(props.obj){
            setNewName(props.obj.name)
            setNewPhone(props.obj.phone)
            setCellula_staff((props.obj.cellula_staff) ? "Yes" : "No")
        }

    }, [props.obj])

    let infoChangeHandler = (e) => {
        if(e.currentTarget.name === "newName"){
            setNewName(e.currentTarget.value)
        }
        if(e.currentTarget.name === "newPhone"){
            setNewPhone(e.currentTarget.value)
        }
        if(e.currentTarget.name === "cellula_staff"){
            setCellula_staff(e.currentTarget.value)
        }
    }

    return (
        <div className={"accountInfoChangeModal-container"}>
            <Modal className={"account-info-change-modal"} show={props.show} onHide={props.handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Account Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter Name"
                                name={"newName"}
                                value={newName}
                                onChange={infoChangeHandler}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                required
                                type="phone"
                                placeholder="Enter Phone"
                                name={"newPhone"}
                                value={newPhone}
                                onChange={infoChangeHandler}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Cellula Staff <sup>(1)</sup> </Form.Label>
                            <Form.Control
                                as="select"
                                name={"cellula_staff"}
                                value={cellula_staff}
                                onChange={infoChangeHandler}>
                                <option>Yes</option>
                                <option>No</option>
                            </Form.Control>
                            <Form.Label className={"cellula_staff_reference"}>(1) Cellula Staff is not required to complete phone verification</Form.Label>
                        </Form.Group>

                        <Form.Group className={"buttons"}>
                            <Button variant="primary" onClick={() => props.resetPassword(props.type, props.obj.email)}>
                                Reset Password
                            </Button>
                            <Button variant="primary" onClick={() => props.disableAccount(props.type, props.obj.email, !props.obj.disabled)}>
                                {(props.obj && props.obj.disabled) ? "Activate" :  "Disable"} Account
                            </Button>
                            <Button variant="primary" onClick={() => props.deleteAccount(props.type, props.obj.email)}>
                                Delete Account
                            </Button>
                        </Form.Group>

                        <Form.Group className={"account-info-msg"}>
                            <Form.Label>{props.accountInfoMsg}</Form.Label>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={"confirm-btn"} variant="primary" onClick={() => props.submit(props.type, props.obj.email, newName, newPhone, cellula_staff)}>
                        Confirm Changes
                    </Button>
                    <Button className={"close-btn"} variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AccountInfoChangeModal