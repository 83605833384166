const initState_2 =  {
    authError : null,
    authToken : null,
    currentUser : null,
    staff : {
        isStaff : false,
        isLoading : false
    },
    passwordSignIn : {
        signedIn : false,
        error    : null
    },
    phoneSignIn : {
        signedIn : false,
        phoneCodeSent : false,
        phoneCodeError : null,
        phoneVerificationMsg : null,
        phoneVerificationError : null
    }
}

const authReducer = (state = initState_2, action) => {
    switch (action.type) {
        case "LOGIN_EMAIL_SUCCESS" :
            return {
                ...state,
                authError: null,
                authToken: action.token,
                currentUser: action.currentUser,
                staff: {
                    ...state.staff,
                    isStaff: false,
                    isLoading: true
                },
                passwordSignIn: {
                    ...state.passwordSignIn,
                    signedIn : true,
                    error    : null
                }
            }
        case "LOGIN_EMAIL_ERROR" :
            return {
                ...state,
                authError : action.error,
                authToken: null,
                currentUser: null,
                staff: {
                    ...state.staff,
                    isStaff: false,
                    loading: false
                },
                passwordSignIn: {
                    ...state.passwordSignIn,
                    signedIn : false,
                    error    : action.error
                }
            }
        case "PHONE_CODE_SENT_SUCCESS" :
            return {
                ...state,
                phoneSignIn : {
                    ...state.phoneSignIn,
                    signedIn : false,
                    phoneCodeSent : true,
                    phoneCodeError : null,
                    phoneVerificationMsg : null,
                    phoneVerificationError : null
                }
            }
        case "PHONE_CODE_SENT_ERROR" :
            return {
                ...state,
                phoneSignIn : {
                    ...state.phoneSignIn,
                    signedIn : false,
                    phoneCodeSent : false,
                    phoneCodeError : action.error,
                    phoneVerificationMsg : null,
                    phoneVerificationError : null
                }
            }
        case "LOGIN_PHONE_SUCCESS" :
            return {
                ...state,
                phoneSignIn : {
                    ...state.phoneSignIn,
                    signedIn : true,
                    phoneVerificationMsg : action.result,
                    phoneVerificationError : null
                }
            }
        case "LOGIN_PHONE_ERROR" :
            return {
                ...state,
                phoneSignIn : {
                    ...state.phoneSignIn,
                    signedIn : false,
                    phoneVerificationMsg : null,
                    phoneVerificationError : action.result
                }
            }
        case "LOGOUT_SUCCESS" :
            return  {
                ...state,
                authError: null,
                authToken: null,
                currentUser: null,
                passwordSignIn : {
                    ...state.passwordSignIn,
                    signedIn : false,
                    error    : null
                },
                phoneSignIn : {
                    ...state.phoneSignIn,
                    signedIn : false,
                    phoneCodeSent : false,
                    phoneCodeError : null,
                    phoneVerificationMsg : null,
                    phoneVerificationError : null
                }

            }
        case "LOGIN_CELLULA_STAFF" :
            return {
                ...state,
                staff: {
                    ...state.staff,
                    isStaff: action.isStaff,
                    isLoading: action.isLoading
                },
                phoneSignIn : {
                    ...state.phoneSignIn,
                    signedIn : true,
                    phoneVerificationMsg : "Cellula Staff Logged In",
                    phoneVerificationError : null
                }
            }
        case "LOGOUT_ERROR" :
            return {
                ...state
            }
        default :
            return state
    }
}

export default authReducer