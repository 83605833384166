//Base Import
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

//Node Modules Imports
import * as serviceWorker from './serviceWorker';
import {getFirebase, ReactReduxFirebaseProvider} from "react-redux-firebase";
import {createStore, applyMiddleware, compose} from "redux";
import {getFirestore, reduxFirestore, createFirestoreInstance} from "redux-firestore";
import {Provider} from "react-redux";
import thunk from "redux-thunk";

//Files/Component/Container Imports
import App from './App';
import rootReducer from "./store/reducers/rootReducer";
import firebaseConfig from './config/firebase/firebase';
import reduxFirebaseConfig from "./config/reduxFirebase/reduxFirebase"

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})),
        reduxFirestore(firebaseConfig)
    )
);

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider
            firebase={firebaseConfig}
            config={reduxFirebaseConfig}
            dispatch={store.dispatch}
            createFirestoreInstance={createFirestoreInstance}>

            <App/>

        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();