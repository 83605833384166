//Base Import
import React, {useState, useEffect} from "react"
import "./MessageLogs.css"

//Node Modules Imports
import {connect} from "react-redux"

//Files/Component/Container Imports
import MessageCards from "../../../UI/Cards/MessageCards/MessageCards";

//MessageLogs Component: Shows Current and Previous Vehicle Message Info
let MessageLogs = (props) => {
    let [selectedDay, setSelectedDay] = useState(null)
    let [data, setData]               = useState(null)

    //Set Message Logs Data with Message Cards
    useEffect(() => {

        //If Data is loaded and Current mission msg-log is loaded
        if(props.data && props.currentMission && props.data['solus-lr'] && props.data['solus-lr'][props.currentMission] && props.data['solus-lr'][props.currentMission]["status"] && props.data['solus-lr'][props.currentMission]["status"]['msg-log']) {

            //All Days recorded data(msg-log)
            let daysData = props.data['solus-lr'][props.currentMission]["status"]['msg-log']
            let dayArray = Object.keys(daysData)
            let day = []

            //If Day is Selected
            if (selectedDay) {

                //Iterate through the days data
                dayArray.map(item => {

                    //if day is same as selected day
                    if (item === selectedDay) {
                        day.push(item)
                    }
                })
            }

            //If not Day is selected
            if (!selectedDay) {
                day = dayArray
            }

            //If selected day data is not defined
            if (day.length !== 0) {
                setData(
                    day.map(day => {
                        return (
                            <MessageCards
                                key={day}
                                data={daysData[day]}
                                status={"message-logs"}
                                currentMission={props.currentMission}/>
                        )
                    })
                )
            }
        }
    }, [props.data['solus-lr'], props.currentMission, selectedDay])

    //Set Selected Day
    let daySelectionBtnHandler = () => {

        //Set Data to "Loading" before Day is set
        setData(
            <div className={"data-cards-loading"}>Loading...</div>
        )
        let day = document.getElementById("msg-day-select")
        setSelectedDay(day.value)
    }

    let filterDays = null

    //If Days of recorded data is defined
    if(props.daysCollections.length !== 0){
        //Set Filter days with selectors to select day
        filterDays = (
            <div className={"selectors"}>
                <select name="msg-day-select" id={"msg-day-select"}>
                    /*Base Value*/
                    <option value={null}>Day</option>
                    {
                        props.daysCollections.map(day => {
                            return (
                                <option key={day} value={day}>{day}</option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }

    return (
        <div className={"message-logs-container"}>
            <div className={"heading"}>
                <h5>
                    Select Day Here for message history of the vehicle
                </h5>
            </div>
            <div className={"selected-message-day"}>
                {filterDays}
                <div className={"search-button"}>
                    <button onClick={daySelectionBtnHandler}>
                        <i className="fas fa-search"></i>
                    </button>
                </div>
            </div>

            <div className={"message-cards"}>
                {data}
            </div>
        </div>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state, ownProps) => {
    return {
        data : state.firestore.data,
        days : state.data.days
    }
}

//Connect to the Redux Store State and Export the Component
export default connect(mapStateToProps)(MessageLogs)