//Base Import
import React, {useEffect, useState} from "react"
import "./UserDashboard.css"

//Node Modules Imports
import {connect}             from "react-redux"
import {Redirect}            from "react-router";
import {useFirestoreConnect} from "react-redux-firebase";

//Files/Component/Container Imports
import MapInfo        from "../../UI/MapInfo/MapInfo";
import MessageLogs    from "./MessageLogs/MessageLogs";
import MapContainer   from "../../UI/Map/MapContainer";
import CurrentStatus  from "./CurrentStatus/CurrentStatus";
import MissionSelect  from "./MissionSelect/MissionSelect";
import PreviousStatus from "./PreviousStatus/PreviousStatus";
import * as ROUTES    from "../../../constants/routes/routes";

//UserDashboard Component: Shows the User with all the different Status associated with the vehicle info/data
let UserDashboard = (props) => {
    let [status, setStatus]                                 = useState(null)
    let [mapDataInfo, setMapDataInfo]                       = useState(null)
    let [currentMission, setCurrentMission]                 = useState(null)
    let [daysCollections, setDaysCollections]               = useState([])
    let [missionDaysCollections, setMissionDaysCollections] = useState([])
    let [missionNameCollections, setMissionNameCollections] = useState([])

    //Set Firestore Collection Query to get User Data
    useEffect(() => {

        //If Signed In
        if(props.auth.currentUser && props.passwordSignIn && props.passwordSignIn.signedIn && props.phoneSignIn && props.phoneSignIn.signedIn){

            //Set Firestore Collection Query to get User Data and then Mission-Names
            setMissionNameCollections( [
                {
                    collection : 'accounts',
                    doc : (props.isAdmin ? "admins" : "users"),
                    subcollections : [
                        {
                            collection : props.auth.currentUser.uid,
                            doc        : props.auth.currentUser.email
                        }
                    ]
                }
            ])
        }
    },[props.auth, props.passwordSignIn, props.phoneSignIn, props.isAdmin])

    //Set CurrentMission and DaysCollections
    useEffect(() => {

        //If Data from Firestore is loaded, But Current Mission Name is not Defined
        if(props.data && props.data.accounts && !currentMission){

            //TODO REMOVE THIS SINCE CURRENT MISSION NAME RETRIEVAL HAS BEEN CHANGED
            // //Get User details(id and email)
            // let uid   = props.auth.currentUser.uid
            // let email = props.auth.currentUser.email
            //
            // //Check is Admin Data is Loaded
            // if(props.isAdmin && props.data.accounts.admins && props.data.accounts.admins[uid] && props.data.accounts.admins[uid][email]){
            //     //Set Current Mission as the Most Recent Mission in Admin Data
            //     setCurrentMission(props.data.accounts.admins[uid][email].role[0])
            // }
            //
            // //Check is User Data is Loaded
            // if(!props.isAdmin && props.data.accounts.users && props.data.accounts.users[uid] && props.data.accounts.users[uid][email]) {
            //     //Set Current Mission as the Most Recent Mission in User Data
            //     setCurrentMission(props.data.accounts.users[uid][email].role[0])
            // }

            if(props.data && props.data['solus-lr'] && props.data['solus-lr']['missions']){

                let last_index     = props.data['solus-lr']['missions'].titles.length - 1
                let latest_mission = props.data['solus-lr']['missions'].titles[last_index]

                setCurrentMission(latest_mission)
            }
        }

        //If Data from Firestore is loaded and Current Mission Name is Defined
        if(props.data && currentMission && props.data['solus-lr'] && props.data['solus-lr'][currentMission]){

            if(props.data['solus-lr'][currentMission]["days-recorded"]){
                //Set Days when data was recorded for the Current Mission as an Array of Days
                setDaysCollections(props.data["solus-lr"][currentMission]["days-recorded"])
            }
            if(props.data['solus-lr'][currentMission]["mission-file-days-recorded"]){
                //Set Days when data was recorded for the Current Mission as an Array of Days
                setMissionDaysCollections(props.data["solus-lr"][currentMission]["mission-file-days-recorded"])
            }

        }
    }, [props.data])

    //Set Status
    useEffect(() => {

        //CurrentMission is Defined
        if(currentMission){

            //Set Status of the UserDashboard Component as the Current Mission
            setStatus(<CurrentStatus currentMission={currentMission} mapDataHandler={mapDataHandler}/>)
        }
    }, [currentMission])

    //Returns Firestore Collection Query for Firebase Connect
    let getCollectionQuery = (type) => {
        if(type === "mission-file-log"){
            return{
                collection: 'solus-lr',
                doc: currentMission, //Active/Selected Mission
                subcollections: [
                    {
                        collection: "status",
                        doc : type //data-log, msg-log or mission-file-log
                    },
                    {
                        collection: missionDaysCollections[missionDaysCollections.length-1] //Get only the most recent/latest day
                    }]
            }
        }
        else {
            return{
                collection: 'solus-lr',
                doc: currentMission, //Active/Selected Mission
                subcollections: [
                    {
                        collection: "status",
                        doc : type //data-log, msg-log or mission-file-log
                    },
                    {
                        collection: daysCollections[daysCollections.length-1] //Get only the most recent/latest day
                    }]
            }
        }
    }

    let DataCollections        = []
    let MsgCollections         = []
    let MissionFileCollections = []
    let MissionFileStatus      = []

    //If Days data was recorded is loaded
    if(daysCollections.length !== 0) {

        //Store Firestore Collection Query for vehicle data
        DataCollections        = getCollectionQuery("data-log")
        MsgCollections         = getCollectionQuery("msg-log")
        MissionFileCollections = (missionDaysCollections.length !== 0) ? getCollectionQuery("mission-file-log") : console.log("NO MISSION FILE");

        if(missionDaysCollections.length !== 0) {
            MissionFileStatus = {
                collection : "solus-lr",
                doc : currentMission,
                subcollections : [{
                    collection : "status",
                    doc : "mission-file-log"
                }]
            }
        }
    }

    //Get Current Mission Data
    useFirestoreConnect([{collection : "solus-lr", doc : currentMission}])

    //Get Mission File Status (enabled or disabled)
    useFirestoreConnect(() => MissionFileStatus)

    //Get Vehicle Data
    useFirestoreConnect(() => DataCollections)
    useFirestoreConnect(() => MsgCollections)
    useFirestoreConnect(() => MissionFileCollections)

    //Get all User/Admin Data
    useFirestoreConnect(() => missionNameCollections)

    let noUser = null

    //If Not Signed-In
    if(!props.auth.currentUser || (!props.passwordSignIn && !props.passwordSignIn.signedIn) || (!props.phoneSignIn && !props.phoneSignIn.signedIn)){

        //Redirect to Sign-In Page
        noUser = (
            <Redirect to={ROUTES.SIGN_IN}/>
        )
    }

    //Set MapDataInfo with location(mapDataInfo)
    let mapDataHandler = (location) => {
        setMapDataInfo(location)
    }

    //Set UserDashboard Status to CurrentStatus
    let currentStatusBtnHandler = () => {
        if(window.outerWidth >= 700){
            //Scroll Up
            window.scroll({
                top: -700,
                behavior: 'smooth'
            });
        }

        //Set UserDashboard Status to CurrentStatus
        setStatus(<CurrentStatus currentMission={currentMission} mapDataHandler={mapDataHandler}/>)
    }

    //Set UserDashboard Status to PreviousStatus
    let previousStatusBtnHandler = () => {
        //Scroll Down
        window.scroll({
            top: 700,
            behavior: 'smooth'
        });
        //Set UserDashboard Status to PreviousStatus
        setStatus(<PreviousStatus currentMission={currentMission} daysCollections={daysCollections} mapDataHandler={mapDataHandler}/>)
    }

    //Set UserDashboard Status to MessageLogs
    let messageLogBtnHandler = () => {
        window.scroll({
            top: 700,
            behavior: 'smooth'
        });
        //Set UserDashboard Status to MessageLogs
        setStatus(<MessageLogs currentMission={currentMission} daysCollections={daysCollections}/>)
    }

    //Change Current Mission to newMission
    let setMissionHandler = (newMission) => {
        //Set CurrentMission
        setCurrentMission(newMission)
        setStatus(<CurrentStatus currentMission={currentMission} mapDataHandler={mapDataHandler}/>)
        setStatus(<MissionSelect currentMission={currentMission} setMissionHandler={(newMission) => setMissionHandler(newMission)}/>)
    }

    //Set UserDashboard Status to MissionSelect
    let missionClickBtnHandler = () => {
        //Scroll Up
        window.scroll({
            top: 700,
            behavior: 'smooth'
        });
        //Set UserDashboard Status to MissionSelect
        setStatus(<MissionSelect currentMission={currentMission} isAdmin={props.isAdmin} setMissionHandler={(newMission) => setMissionHandler(newMission)}/>)
    }

    return (
        <div className={"userDashboard-container"}>
            <div className={"signIn-redirect"}>
                {noUser}
            </div>

            <div className={"map-container"}>
                <MapInfo mapData={mapDataInfo} currentMission={currentMission}/>
                <MapContainer mapData={mapDataInfo} currentMission={currentMission}/>
            </div>

            <div className={"info-container"} id={"userboard-info-container"}>
                <div className={"navigation"}>
                    <button id={"current-status-btn"} onClick={currentStatusBtnHandler}>
                        Current Status
                        <div></div>
                    </button>
                    <button onClick={previousStatusBtnHandler}>
                        Previous Status
                        <div></div>
                    </button>
                    <button onClick={messageLogBtnHandler}>
                        Message Logs
                        <div></div>
                    </button>
                    <button onClick={missionClickBtnHandler}>
                        Mission Select
                        <div></div>
                    </button>
                </div>

                {status}
            </div>
        </div>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state) => {
    return {
        auth           : state.auth,
        passwordSignIn : state.auth.passwordSignIn,
        phoneSignIn    : state.auth.phoneSignIn,
        data           : state.firestore.data,
        msg            : state.data.msg,
        error          : state.data.error,
        days           : state.data.days
    }
}

//Get Actions from the Redux Store
const mapDispatchToProps = (dispatch) => {
    return {
    }
}

//Connect to the Redux Store State, Actions and Export the Component
export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard)