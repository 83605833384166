//Base Import
import React, {useState, useEffect} from "react"
import "./MessageCards.css"

//Node Modules Imports

//Files/Component/Container Imports

//MessageCards Component: Shows Message Cards of the Vehicle msg-logs of Current Mission and Selected Day
let MessageCards = (props) => {
    const [entries, setEntries] = useState([])

    //Set Entries Array of Sorted Messages by time
    useEffect(() => {
        let entry = []
        for(let key in props.data){
            //Get Data Instant()
            entry.push(props.data[key]["entry"])
        }

        //Sort by Time log was created
        let sortedEntry = []
        if(props.status === "message-logs"){
            if(entry[0].sec){
                //Same old message Data sets don't have seconds in them
                sortedEntry = entry.sort((log1,log2) => {return log1.sec - log2.sec})
            }
            else {
                sortedEntry = entry
            }
        }
        setEntries(sortedEntry)
    }, [props.data, props.status])

    //Get Current Time
    let getCurrentTime = (type) => {
        let currentTime             = new Date()
        if(type === "string"){
            return (
                currentTime.getHours().toString().trim() + ":" + currentTime.getMinutes().toString().trim() + ":" + currentTime.getSeconds().toString().trim() + ":" + currentTime.getMilliseconds().toString().trim() + " PST"
            )
        }
        return null
    }

    let messages = null

    //If Entries Array is Defined
    if(entries.length!==0){

        //Iterate the Entries array
        messages = entries.map(msg => {

            //Return Msg JSX
            return (
                <div className={"message"}>
                    <div className={"text"}>
                        <h5>
                            {msg.msg}
                        </h5>
                    </div>
                    <div className={"time"}>
                        {msg.time}
                    </div>
                </div>
            )
        })
    }

    return (
        <div className={"message-card-container"}>
            <div className={"main-info"}>
                <div className={"day"}>
                    <h3>
                        {(entries && entries[0]) ? entries[0].day : ""}
                    </h3>
                </div>

                <div className={"current-mission-name"}>
                    <h6>
                        Current Mission : {props.currentMission}
                    </h6>
                </div>
                <div className={"current-time"}>
                    <h6>
                        Current Time: {getCurrentTime("string")}
                    </h6>
                </div>
            </div>

            <div className={"vertical-line"}></div>

            <div className={"messages"}>
                {messages}
            </div>
        </div>
    )
}

//Export Component
export default MessageCards