//Base Import
import React, {useEffect,useState} from "react"
import "./Accounts.css"

//Node Modules Imports
import axios     from "axios"
import {connect} from "react-redux"
import Table     from "react-bootstrap/Table";

//Files/Component/Container Imports
import * as ROUTES            from "../../../../constants/routes/routes"
import AddNewAccountModal     from "../../../UI/Modal/AddNewAccountModal/AddNewAccountModal";
import AccountInfoChangeModal from "../../../UI/Modal/AccountInfoChangeModal/AccountInfoChangeModal"

let Accounts = (props) => {
    let [accountData, setAccountData]                           = useState([])
    let [activeObj, setActiveObj]                               = useState(null)
    let [modalShow, setModalShow]                               = useState(false)
    let [newAccountMessage, setNewAccountMessage]               = useState("")
    let [newAccountModalShow, setNewAccountModalShow]           = useState(false)
    let [accountInfoChangeMessage, setAccountInfoChangeMessage] = useState("")


    useEffect( () => {
        if(props.data && props.data.accounts && props.data.accounts[props.type + "List"] && props.data.accounts[props.type]){
            if(props.data.accounts[props.type+"List"].list.length === 0){
                setAccountData(
                    <div className={"empty-list"}>
                        No {props.type}, Add {props.type} by clicking "Add New {props.type} above"
                    </div>
                )
            }
            else {
                setAccountData(
                    props.data.accounts[props.type+"List"].list.map((id,index) => {
                        if (props.data.accounts[props.type][id]) {
                            let key = Object.keys(props.data.accounts[props.type][id])[0]
                            let obj = (props.data.accounts[props.type][id])[key]
                            return (
                                accountInfo(obj, index)
                            )
                        } else {
                            return null
                        }
                    })
                )
            }
        }
    },[props.accountList, props.data.accounts, props.data.accounts[props.type]])

    let passwordChangeHandler = (accountType, accountEmail) => {
        setAccountInfoChangeMessage("Loading...")
        axios
            .post(
                ROUTES.AUTH_SERVER_LESS + "/auth/updatePassByAdmin",
                {
                    accountType  : accountType.toLowerCase(),
                    accountEmail : accountEmail,
                    adminToken   : props.auth.authToken,
                    adminEmail   : props.auth.currentUser.email
                })
            .then((msg) => {
                setAccountInfoChangeMessage(msg.data)
            })
            .catch(error => {
                setAccountInfoChangeMessage("Error - " + error.response.data)
            })
    }

    let editAccountHandler = (obj) => {
        setModalShow(true)
        setActiveObj(obj)
    }

    let deleteAccountHandler = (accountType, accountEmail) => {
        setAccountInfoChangeMessage("Loading...")
        axios
            .post(
                ROUTES.AUTH_SERVER_LESS + "/auth/deleteAccount",
                {
                    accountType          : accountType.toLowerCase(),
                    accountEmail         : accountEmail,
                    adminToken           : props.auth.authToken,
                    adminEmail           : props.auth.currentUser.email
                })
            .then((msg) => {
                setAccountInfoChangeMessage(msg.data)
            })
            .then(() => {
                setModalShow(false)
            })
            .catch(error => {
                setAccountInfoChangeMessage("Error - " + error.response.data)
            })
    }

    let disableAccountHandler = (accountType, accountEmail, accountDisableStatus) => {
        setAccountInfoChangeMessage("Loading...")
        axios
            .post(
                ROUTES.AUTH_SERVER_LESS + "/auth/disableAccount",
                {
                    accountType          : accountType.toLowerCase(),
                    accountDisableStatus : accountDisableStatus,
                    accountEmail         : accountEmail,
                    adminToken           : props.auth.authToken,
                    adminEmail           : props.auth.currentUser.email
                })
            .then((msg) => {
                setAccountInfoChangeMessage(msg.data)
            })
            .catch(error => {
                setAccountInfoChangeMessage("Error  - " + error.response.data)
            })
    }

    let submitHandler = (accountType, accountEmail, accountName, accountPhone, accountIsStaff) => {

        setAccountInfoChangeMessage("Loading...")
        axios
            .post(
                ROUTES.AUTH_SERVER_LESS + "/auth/updateAccountDetails",
                {
                    accountType : accountType.toLowerCase(),
                    accountName : accountName,
                    accountPhone : accountPhone,
                    accountEmail : accountEmail,
                    accountIsStaff : (accountIsStaff === "Yes"),
                    adminToken : props.auth.authToken,
                    adminEmail : props.auth.currentUser.email
                })
            .then((msg) => {
                setAccountInfoChangeMessage(msg.data)
            })
            .catch(error => {
                setAccountInfoChangeMessage("Error - " + error.response.data)
            })
    }

    let newAccountSubmitHandler = (accountType, accountEmail, accountName, accountPhone, accountIsStaff) => {
        setNewAccountMessage("Loading...")
        axios
            .post(
                ROUTES.AUTH_SERVER_LESS + "/auth/signUp",
                {
                    accountType : accountType.toLowerCase(),
                    accountName : accountName,
                    accountPhone : accountPhone,
                    accountEmail : accountEmail,
                    accountIsStaff : (accountIsStaff === "Yes"),
                    adminToken : props.auth.authToken,
                    adminEmail : props.auth.currentUser.email
                })
            .then((msg) => {
                setNewAccountMessage(msg.data)
            })
            .catch(error => {
                setNewAccountMessage("Error - " +  error.response.data)
            })
    }

    let addNewAccount = () => {
        setNewAccountModalShow(true)
    }

    let accountInfo = (obj,index) => {
        return (
            <React.Fragment key={obj.email}>
                <tr key={obj.email}>
                    <td>{index+1}</td>
                    <td>{obj.id}</td>
                    <td>{obj.name}</td>
                    <td>{obj.email}</td>
                    <td>{(obj.emailVerified) ? "Yes" : "No"}</td>
                    <td>{obj.phone}</td>
                    <td>{obj.status}</td>
                    <td>{(obj.cellula_staff) ? "Yes" : "No"}</td>
                    <td>{obj["account-created-by"]}</td>
                    <td className={"edit-account-btn"}>
                        <button onClick={() => editAccountHandler(obj)}>
                            <i className="fas fa-user-edit"></i>
                        </button>
                    </td>
                </tr>
            </React.Fragment>
        )
    }

    return (
        <div className={"accounts-container"}>
            <div className={"add-new-user"}>
                <button onClick={addNewAccount}>
                    <span> Add New {props.type.charAt(0).toUpperCase() + props.type.slice(1)} </span>
                </button>
                <AddNewAccountModal
                    type={props.type.charAt(0).toUpperCase() + props.type.slice(1)}
                    show={newAccountModalShow}
                    accountInfoMsg={newAccountMessage}
                    handleClose={() => {setNewAccountModalShow(false); setNewAccountMessage("");}}
                    submit={newAccountSubmitHandler}/>
            </div>
            <div className={"account-list"}>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>User ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Email Verified</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th>Cellula Staff</th>
                        <th>Account Created By</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {(accountData) ?  accountData : null}
                    </tbody>
                </Table>
            </div>
            <AccountInfoChangeModal
                type={props.type}
                show={modalShow}
                disableAccount={disableAccountHandler}
                deleteAccount={deleteAccountHandler}
                resetPassword={passwordChangeHandler}
                accountInfoMsg={accountInfoChangeMessage}
                handleClose={() => {setModalShow(false); setAccountInfoChangeMessage("")}}
                submit={submitHandler}
                obj={activeObj}/>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth : state.auth,
        data : state.firestore.data,
    }
}

export default connect(mapStateToProps, null)(Accounts)