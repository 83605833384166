//Base Import
import React from "react"
import "./AdminAccount.css"

//Node Modules Imports

//Files/Component/Container Imports

let AdminAccount = (props) => {
    return (
        <div className={"admin-account-container"}>
            <div className={"navigation"}>
                <button onClick={() => props.statusHandler("users")}>
                    Users
                    <div></div>
                </button>
                <button onClick={() => props.statusHandler("admins")}>
                    Admins
                    <div></div>
                </button>
            </div>
        </div>
    )
}

export default AdminAccount
