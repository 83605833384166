//Base Import
import React, {useState, useEffect} from "react"
import "./DropDown.css"

//Node Modules Imports
import {Link} from "react-router-dom";

//Files/Component/Container Imports

let DropDown = (props) => {

    let [dropDownName, setDropDownName] = useState("")
    let [dropDownValue, setDropDownValue] = useState([])


    useEffect(() => {
        setDropDownName(props.name)
        setDropDownValue(props.value)
    }, [props.name, props.value])

    let links = []

    //If normal NavBar DropDown
    if(dropDownValue && dropDownValue.length !== 0){
        links = dropDownValue.map(item => {
            return (
                <a
                    href={item.val}
                    target={"_blank"}
                    key={item.name}>
                    {item.name}
                </a>
            )
        })
    }

    let customBtnClassName = "dropdown-content"

    //If userIcon
    if(dropDownName === "user-icons"){
        links = dropDownValue.map(item => {
            if(item.type === "button"){
                return (
                    <button
                        key={item.name}
                        onClick={item.clickHandler}>
                        {item.value}
                    </button>
                )
            }
            if(item.type === "link"){
                return (
                    <Link to={item.to}>{item.value}</Link>
                )
            }
            return null
        })
        customBtnClassName += " rightZero"
    }


    return (
        <div className="dropdown">
            <button
                className="dropbtn">
                {(props.children) ? props.children : dropDownName}
            </button>

            <div className={customBtnClassName}>
                <div className={"container"}>
                    {links}
                </div>

            </div>
        </div>
    )
}

export default DropDown

