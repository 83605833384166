//Base Import
import React, {useState} from "react"
import "./PhoneVerification.css"

//Node Modules Imports
import {connect}                 from "react-redux";
import {Modal, Button}           from "react-bootstrap";
import Form                      from "react-bootstrap/Form";
import {signInComplete, signInPhoneVerification} from "../../../../store/actions/authActions";

//Files/Component/Container Imports

//PhoneVerification Component: Verifies user phone verification code
let PhoneVerification = (props) => {

    let [verificationCode, setVerificationCode] = useState("")
    let [verificationMsg, setVerificationMsg]   = useState("")

    //Verification code field change handler
    let handleCodeChange = (e) => {

        //Prevent Default
        e.preventDefault()
        console.log("PHONE VERIFICATION MODAL (verificationCodeChange)- ", e.target)

        //Target should be coming from input verification code
        if(e.target.name === "verificationCode"){

            //Set Verification Code as is(type string)
            setVerificationCode(e.target.value)

            // //If Value is Empty String
            // if(e.target.value === ""){
            //     setVerificationCode("")
            // }
            //
            // else {
            //     //Parse and Set the code
            //     setVerificationCode((Number.parseFloat(e.target.value).toFixed(0)))
            // }
        }
    }

    //Verification Form handler
    let handleVerify = (e) => {

        //Prevent Default
        e.preventDefault()

        //If verification code field is empty
        if(verificationCode !== ""){

            //Verification Code of type Number
            let code_type_number = (Number.parseFloat(verificationCode).toFixed(0))

            console.log("PHONE VERIFICATION MODAL (verificationCode)- ", code_type_number)

            //Verify code
            window.confirmationResult.confirm(code_type_number)
                .then((result) => {

                    //Set Verification Message
                    setVerificationMsg("Verification Successful")

                    //Call SignInComplete
                    props.signInComplete(true, verificationMsg)

                    //Redirect to Dashboard
                    props.phoneVerified()

                    //Close the Modal
                    props.close()
                }).catch((error) => {

                    //Call SignInComplete
                    props.signInComplete(false, verificationMsg)

                    //Set Verification Message
                    setVerificationMsg(error.message)
                })
        }
        else {

            //Set Verification Message
            setVerificationMsg("Please Enter the Code")
        }
    }

    //Resend Verification Code handler
    let handleReSendCode = () => {

        //Re-send code
        props.signInPhoneVerification()

        //Set Verification Message
        setVerificationMsg("Code Re-Sent to " + props.phoneNumber)
    }

    //Phone Verification Modal Close handler
    let handleModalClose = () => {

        //Set Verification Code
        setVerificationMsg("")

        //Close Phone Verification Modal
        props.close()
    }

    return (
        <div className={"phone-verification-container"}>
            <Modal className={"phone-verification-modal"} show={props.show} onHide={handleModalClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Phone Verification
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Verification Code Send to {props.phoneNumber}</Form.Label>
                            <Form.Control
                                type        = "text"
                                placeholder = "Enter Code"
                                name        = {"verificationCode"}
                                value       = {verificationCode}
                                onChange    = {handleCodeChange}/>
                        </Form.Group>

                        <Form.Group className={"account-info-msg"}>
                            <Form.Label>{verificationMsg}</Form.Label>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={"re-send-code"} onClick={handleReSendCode}>Re-send Code</Button>
                    <Button className={"verify-code"} onClick={handleVerify}>Verify</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

//Get State from the Redux Store
const mapStateToProps = (state) => {
    return {
    }
}


//Get Actions from the Redux Store
const mapDispatchToProps = (dispatch) => {
    return {
        signInPhoneVerification : () => dispatch(signInPhoneVerification()),
        signInComplete          : (isSuccess, result) => dispatch(signInComplete(isSuccess, result))
    }
}

//Connect to the Redux Store State and Export the Component
export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerification)