//Base Imports
import React from 'react';
import './App.css';

//Node Modules Imports
import {Redirect, Route, Switch} from "react-router-dom";
import {BrowserRouter} from "react-router-dom";

//Files/Component/Container Imports
import SignIn from "./Components/auth/SignIn/SignIn";
import * as ROUTES from "./constants/routes/routes";
import Navigation from "./Components/UI/Navigation/Navigation";
import UserDashboard from "./Components/dashboard/UserDashboard/UserDashboard";
import AdminDashboard from "./Components/dashboard/AdminDashboard/AdminDashboard";
import Dashboard from "./Components/dashboard/Dashboard/Dashboard";
import AccountInfo from "./Components/updates/AccountInfo/AccountInfo";
import PwForget from "./Components/auth/PwForget/PwForget";

function App() {

    return (
        <BrowserRouter>
            <div className={"App"}>
                <header className={"navbar-container"}>
                    <Navigation/>
                </header>

                <hr className={"horizontal-ruler"}/>
                <main id={"main-container"}>
                    <Switch>
                        <Route exact path={ROUTES.DASHBOARD} component={Dashboard}/>
                        <Route exact path={ROUTES.USER_DASHBOARD} component={UserDashboard}/>
                        <Route exact path={ROUTES.ADMIN_DASHBOARD} component={AdminDashboard}/>
                        <Route exact path={ROUTES.SIGN_IN} component={SignIn}/>
                        <Route exact path={ROUTES.PASSWORD_FORGET} component={PwForget}/>
                        <Route exact path={ROUTES.ACCOUNT_INFO} component={AccountInfo}/>
                    </Switch>

                    <Redirect to={ROUTES.SIGN_IN}/>
                </main>

                {/*<footer>*/}
                {/*    Footer*/}
                {/*</footer>*/}
            </div>
        </BrowserRouter>
  );
}

export default App;
