//Base Import
import React, {useState} from "react"
import "./MissionInfoChangeModal.css"

//Node Modules Imports
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form";

//Files/Component/Container Imports

let MissionInfoChangeModal = (props) => {

    let [newMission, setNewMission] = useState("")
    let [missionFileEnabled, setMissionFileEnabled] = useState(false)

    let changeHandler = (e) => {
        if(e.currentTarget.name === "newMission"){
            setNewMission(e.currentTarget.value)
        }
        if(e.currentTarget.name === "missionFileEnabled"){
            //Set True is enabled and false otherwise
            setMissionFileEnabled((e.currentTarget.value === "Enabled"))
        }
    }

    return (
        <div className={"missionInfoChangeModal-container"}>
            <Modal className={"account-info-change-modal"} show={props.show} onHide={props.handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Mission Setting</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>New Mission Name <sup>(1)</sup> </Form.Label>
                            <Form.Control
                                type="name"
                                placeholder="Enter New Mission Name"
                                name={"newMission"}
                                value={newMission}
                                required={true}
                                onChange={changeHandler}/>
                            <Form.Label className={"cellula_staff_reference"}>(1) Mission Name once entered cannot be renamed or deleted</Form.Label>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Mission Prediction <sup>(2)</sup> </Form.Label>
                            <Form.Control
                                as="select"
                                name={"missionFileEnabled"}
                                value={(missionFileEnabled) ? "Enabled" : "Disabled"}
                                onChange={changeHandler}>
                                <option>Enabled</option>
                                <option>Disabled</option>
                            </Form.Control>
                            <Form.Label className={"cellula_staff_reference"}>(2) Mission Prediction can be enabled/disabled any time</Form.Label>
                        </Form.Group>

                        <Form.Group className={"buttons"}>
                            <Button variant="primary" onClick={() => props.missionFileStatusHandler(props.currentMission, !props.currentMissionFileStatus)}>
                                {props.currentMissionFileStatus ?  "Disable Map Prediction for " : "Enable Map Prediction for "}{props.currentMission}
                            </Button>
                        </Form.Group>

                        <hr/>
                        <Form.Group className={"account-info-msg"}>
                            <Form.Label>{props.missionAddInfoMsg}</Form.Label>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={"confirm-btn"} variant="primary" onClick={() => props.missionAddHandler(newMission, missionFileEnabled)}>
                        Add Mission
                    </Button>
                    <Button className={"close-btn"} variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MissionInfoChangeModal
