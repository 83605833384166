//Base Import
import React, {useState, useEffect} from "react"
import "./UserAccount.css"

//Node Modules Imports
import {connect} from "react-redux"

//Files/Component/Container Imports

let UserAccount = (props) => {

    let [userData, setUserData] = useState(null)

    useEffect(() => {
        if(props.data && props.auth && props.data.accounts && props.data.accounts.users && props.data.accounts.users[props.auth.currentUser.uid] && props.data.accounts.users[props.auth.currentUser.uid][props.auth.currentUser.email]){
            setUserData(props.data.accounts.users[props.auth.currentUser.uid][props.auth.currentUser.email])
        }
        else {
            setUserData(
                <div className={"data-cards-loading"}>Loading...</div>
            )
        }
    },[props.auth, props.data.accounts])

    return (
        <div className={"user-account-container"}>
            <div className={"user-name"}>
                <h6 className={"sub-heading"}>Name :</h6>
                <h6 className={"sub-value"}>{(userData) ? userData.name : null}</h6>
            </div>
            <hr/>
            <div className={"user-email"}>
                <h6 className={"sub-heading"}>Email : </h6>
                <h6 className={"sub-value"}>{(userData) ? userData.email : null}</h6>
            </div>
            <hr/>

            <div className={"user-email-verified"}>
                <h6 className={"sub-heading"}>Email Verified : </h6>
                <h6 className={"sub-value"}>{(userData && userData.emailVerified) ? "Yes" : "No"}</h6>
            </div>
            <hr/>
            <div className={"user-phone"}>
                <h6 className={"sub-heading"}>Phone : </h6>
                <h6 className={"sub-value"}>{(userData) ? userData.phone : null}</h6>
            </div>
            <hr/>
            <div className={"user-date-created"}>
                <h6 className={"sub-heading"}>Data Created : </h6>
                <h6 className={"sub-value"}>{(userData) ? userData.metadata.creationTime : null}</h6>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth : state.auth,
        data : state.firestore.data
    }
}

export default connect(mapStateToProps)(UserAccount)