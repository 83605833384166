const initState = {
    data : null,
    msg  : null,
    error: null,
    days : null
}

const dashBoardReducer = (state = initState, action) => {
    switch (action.type) {
        case "SOLUS_LR_DATA_RECEIVED" :
            return {
                ...state,
                data :action.data.data,
                msg  : action.data.msg
            }
        case "SOLUS_LR_DAYS_COLLECTION" :
            return {
                ...state,
                days: action.data
            }
        case "SOLUS_LR_DATA_RECEIVED_ERROR" || "SOLUS_LR_DAYS_COLLECTION_ERROR" :
            return {
                ...state,
                error : action.error.error,
                msg   : action.error.msg
            }
        default :
            return state
    }
}

export default dashBoardReducer