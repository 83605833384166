export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase()
        const firestore = getFirestore()

        firebase.auth().useDeviceLanguage();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {

            //Separate this here, only sign in and don't assign any current user until phone is verified too.
            return (firebase.auth().currentUser.getIdToken(true))
        })
        .then(token => {
            console.log("Log In(signInWithEmailAndPassword) Success")
            dispatch({type : 'LOGIN_EMAIL_SUCCESS', token : token, currentUser : firebase.auth().currentUser})
            dispatch(checkIfStaff())
        })
        .catch(error  => {
            console.log("Log In(signInWithEmailAndPassword) Error")
            dispatch({type: 'LOGIN_EMAIL_ERROR', error : error})
        })
    }
}

export const checkIfStaff = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        let firestore = getFirestore()
        let firebase  = getFirebase()

        let id = firebase.auth().currentUser.uid
        let email = firebase.auth().currentUser.email

        //Is Cellula Staff?
        //Try searching in users & admins
        // [NOTE - only admins have access to users & admins data, while users only have access to their own data]
        let searchUsers  = firestore.collection("accounts").doc("users").collection(id).doc(email).get()
        let searchAdmins = firestore.collection("accounts").doc("admins").collection(id).doc(email).get()
        Promise.all([searchUsers, searchAdmins]).then((doc) => {
            let users_data  = (doc[0].exists) ? doc[0].data().cellula_staff : false
            let admins_data = (doc[1].exists) ? doc[1].data().cellula_staff : false

            if(users_data || admins_data){
                //Is Cellula Staff
                dispatch({type : 'LOGIN_CELLULA_STAFF', isStaff : true, isLoading : false})
            }
            else {
                dispatch({type : 'LOGIN_CELLULA_STAFF', isStaff : false, isLoading: false})
            }
        })
    }
}

export const signInPhoneVerification = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        console.log("signInPhoneVerification action starting")
        const firebase = getFirebase()
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithPhoneNumber(
            firebase.auth().currentUser.phoneNumber,
            window.recaptchaVerifier
        ).then(function (confirmationResult) {
            console.log("PHONE VERIFICATION AUTH ACTIONS Success")
            window.confirmationResult = confirmationResult;
            dispatch({type : 'PHONE_CODE_SENT_SUCCESS'})
        }).catch(function (error) {
            console.log("PHONE VERIFICATION AUTH ACTIONS Error")
            dispatch({type : 'PHONE_CODE_SENT_ERROR', error : error})
        });
    }
}

export const signInComplete = (isSuccess, result) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        console.log("SIGN IN COMPLETE", isSuccess)
        if(isSuccess){
            dispatch({type : 'LOGIN_PHONE_SUCCESS', result : result})
        }
        else {
            dispatch({type : 'LOGIN_PHONE_ERROR', result : result})
        }
    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase()
        firebase.auth().signOut()
            .then(() => {
                dispatch({type : 'LOGOUT_SUCCESS'})
            })
            .catch(error => [
                dispatch({type: 'LOGOUT_ERROR', error : error})
            ])
    }
}